import { Modal } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { handleDownload } from "../utils/handleDownload";
import ImageViewer from "react-simple-image-viewer";
interface PassportModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  idData?: {
    idCard: string;
    residenceCard: string;
  };
}

export default function PassportModal({
  open,
  onClose,
  setOpen,
  idData,
}: PassportModalProps) {
  const [currentImage, setCurrentImage] = useState("");
  const closeImageViewer = () => {
    setCurrentImage("");
  };
  return (
    <Modal
      className="modal passport_modal"
      id="passportModal"
      aria-labelledby="passport-modal-title"
      aria-describedby="passport-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Passport / ID</h2>
            <div className="btn_flex">
              {/* <Button className="btn btn_highlight">
                <img src="/static/images/import_icon.svg" alt="Icon" /> QR code
              </Button> */}
              {/* <Button className="btn btn_highlight">
                                <img src="/static/images/import_icon.svg" alt="Icon" /> Finger Print
                            </Button> */}
            </div>
          </div>
          {idData?.idCard && (
            <div className="main_title" style={{ textAlign: "center" }}>
              <p>Id Card</p>
            </div>
          )}
          {idData?.idCard && (
            <figure
              className="passport_img"
              style={{ marginTop: "-20px" }}
              onClick={() =>
                setCurrentImage(
                  idData?.idCard
                    ? idData?.idCard
                    : "/static/images/passport_attachment.jpg"
                )
              }
            >
              <img
                src={
                  idData?.idCard
                    ? idData?.idCard
                    : "/static/images/passport_attachment.jpg"
                }
                alt="Passport"
              />
            </figure>
          )}
          {idData?.residenceCard && (
            <div
              className="main_title"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <p>Residence Card</p>
            </div>
          )}

          {idData?.residenceCard && (
            <figure
              className="passport_img"
              style={{ marginTop: "-20px" }}
              onClick={() =>
                setCurrentImage(
                  idData?.residenceCard
                    ? idData?.residenceCard
                    : "/static/images/passport_attachment.jpg"
                )
              }
            >
              <img
                src={
                  idData?.residenceCard
                    ? idData?.residenceCard
                    : "/static/images/passport_attachment.jpg"
                }
                alt="Passport"
              />
            </figure>
          )}
          <div className="footer_btn_flex">
            <Button
              className="btn btn_secondary"
              onClick={() => setOpen(false)}
            >
              Okay
            </Button>
            <Button
              className="btn btn_highlight"
              onClick={() => {
                const temp: any = idData;

                for (let key in temp) {
                  if (temp[key]) {
                    // window.open(temp[key]);
                    handleDownload(temp[key], "id");
                  }
                }
              }}
            >
              Download
            </Button>
          </div>
        </div>
        {currentImage && (
          <ImageViewer
            src={[currentImage]}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
              padding: "5%",
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </Modal>
  );
}
