import { getMessaging, getToken } from "firebase/messaging";
import app from "./firebaseKeys";
import { getFromStorage, setToStorage } from "../helpers";
import { STORAGE_KEYS } from "../helpers/constants/storageKeys";

const firebaseCloudMessaging = {
  // eslint-disable-next-line consistent-return
  init: async () => {
    try {
      const messaging = await getMessaging(app);
      const tokenInLocalForage = await getFromStorage(STORAGE_KEYS.fcmToken);

      if (tokenInLocalForage) {
        return tokenInLocalForage;
      }
      Notification.requestPermission().then(function (permission) {});
      const status = await Notification.requestPermission();

      if (status && status === "granted") {
        const fcm_token = await getToken(messaging, {
          vapidKey:
            "BBrVhv7cTvTrf_o1j62sBOco3KuESMXS03NGDnFZJqElbPW7M2apBe6ZZa9xV4ZLt1UDt2wh9W3NB8oy_NxmnuY",
        });

        if (fcm_token) {
          setToStorage(STORAGE_KEYS.fcmToken, fcm_token);
          return fcm_token;
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
    // }
  },
};

export { firebaseCloudMessaging };
