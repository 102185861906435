import { Button as _Button } from "@mui/material";
import { Colors } from "../constants/colors";

type ButtonProps = {
  value: string|number;
  gradient?: boolean;
  fontSize?: number;
  onClick?: () => void;
  disabled?:boolean|undefined;
};

const Button = ({
  value,
  onClick,
  gradient = false,
  fontSize = 17,
  disabled=false
}: ButtonProps) => {
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_Button
      onClick={onClick}
      style={{
        background: Colors.primary,
        fontWeight: 300,
        fontSize,
        width: "100%",
        textTransform: "capitalize",
      }}
      type="submit"
      variant="contained"
      disabled={disabled}
    >
      {value}
    </_Button>
  );
};

export default Button;
