import React, { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Running in Debug Mode (Development)");
    } else {
      console.log("Running in Release Mode (Production)");
      console.log = () => {};
      console.error = () => {};
    }
  }, []);

  return <Routing />;
};

export default App;
