import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
} from "@mui/material";
import { showToast, showError } from "../../constants/toasts";
import {
  useLazyGetAdminNotificationQuery,
  useDeleteNotificationByIdMutation,
  useDeleteAdminNotificationsMutation,
} from "../../services/manage-notification";
import { AdminNotification } from "../../types/adminNotification";
import { isValidInput } from "../../utils/validations";
import { handleDelete } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";
import Loader from "../../constants/Loader";

const RecievedNotifications = () => {
  const navigate = useNavigate();
  const [adminNotification, setAdminNotification] = useState<
    AdminNotification[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [open2, setOpen2] = useState(false);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const [getAdminNotification] = useLazyGetAdminNotificationQuery();
  const [deleteById] = useDeleteNotificationByIdMutation();
  const [deleteAll] = useDeleteAdminNotificationsMutation();
  const handleDeleteAll = async () => {
    try {
      const response = await deleteAll(null).unwrap();
      if (response.statusCode == 200) {
        showToast("All notifications deleted successfully");
        getAdminNotificationList();
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const getAdminNotificationList = async () => {
    setIsLoading(true);
    try {
      const response = await getAdminNotification({
        page: page,
        // limit: 10,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        if (response?.data?.data?.length == 0 && page > 1) {
          setPage(page - 1);
          return;
        }
        setAdminNotification(response?.data?.data || []);

        setTotalCount(response?.data?.count as number);
      } else {
        setAdminNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    //
    getAdminNotificationList();
  }, [debouncedSearchTerm, page]);
  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Received Notifications</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Received Notifications
        </p>
      </div>

      <div className="cards">
        <div className="table_header">
          <div className="left_s">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
              placeholder="Search..."
            />
          </div>
        </div>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="v3"
          >
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {adminNotification?.length ? (
                adminNotification?.map(
                  (data: AdminNotification, index: number) => {
                    return (
                      <TableRow>
                        <TableCell>{(page - 1) * 10 + index + 1}</TableCell>
                        <TableCell>
                          <div className="user_block">
                            <figure
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate("/profile-detail/" + data?.senderId)
                              }
                            >
                              <img
                                src={
                                  data?.sender?.[0]?.personVerification
                                    ?.profileImage ||
                                  "/static/images/user-placeholder.jpg"
                                }
                                alt=""
                              />
                            </figure>
                            <p>
                              {data?.sender?.[0]?.firstName
                                ? `${data?.sender?.[0]?.firstName} ${data?.sender?.[0]?.lastName}`
                                : "-"}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>{data?.title || ""}</TableCell>
                        <TableCell className="max_c">
                          <p>{data?.message || ""}</p>
                        </TableCell>
                        <TableCell>{data?.createdAt.slice(0, 10)}</TableCell>
                        <TableCell>
                          <Box>
                            <IconButton
                              onClick={() =>
                                handleDelete(
                                  deleteById,
                                  data?._id,
                                  getAdminNotificationList
                                )
                              }
                            >
                              <img
                                src="/static/images/trash_icon.svg"
                                alt="Icon"
                              />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    No Notification Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Pagination
        module={adminNotification}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
    </>
  );
};

export default RecievedNotifications;
