import { showError, showToast } from "../constants/toasts";
import { generateEncryptedKeyBody } from "./crypto";

export const handleDelete = async (
  deleteById: any,
  selectedId: string,
  getAllData: () => void
) => {
  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
};
export const handleDeleteStories = async (
  deleteById: any,
  selectedId: string,
  Id: string | undefined,
  getAllData: () => void
) => {
  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        showToast(`Deleted Successfully`);
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  }
};
export const updateStatus = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
  setData: any,
  data: any
) => {
  const encryptedBody = generateEncryptedKeyBody(data);

  const response = await updateStatusMutation({
    id,
    body: encryptedBody,
  }).unwrap();

  if (response?.statusCode === 200) {
    const updatedData = data.map((x: any) => {
      if (x._id === id) {
        return {
          ...x,
          isBlocked: status,
        };
      }
      return x;
    });

    setData(updatedData);
    // showToast("Status updated successfully");
    if (!status) {
      showToast("Enabled successfully");
    } else {
      showToast("Disabled successfully");
    }
  } else {
    showError("Please try again");
  }
};
