import * as React from "react";
import { Modal } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

interface EditDepartureDateModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  departureDate?: any;
  setDepartureDate?: any;
  editByIdData?: any;
}

export default function EditDepartureDate({
  open,
  onClose,
  setOpen,
  departureDate,
  setDepartureDate,
  editByIdData,
}: EditDepartureDateModalProps) {
  const [date, setDate] = React.useState("");
  React.useEffect(() => {
    setDate(departureDate);
  }, [open]);
  return (
    <Modal
      className="modal datepiker_modal editDeparture_modal"
      id="editDepartureDateModal"
      aria-labelledby="editDepartureDate-modal-title"
      aria-describedby="editDepartureDate-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="modal_title">
            <h2>Edit Departure Date</h2>
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              value={dayjs(`${departureDate}`)}
              minDate={
                dayjs(`${date}`)
                // .subtract(7, "day")
              }
              onChange={(e) => {
                setDepartureDate(e?.toISOString());
              }}
            />
          </LocalizationProvider>
          <div className="footer_btn_flex">
            <Button className="btn btn_white br" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button className="btn btn_secondary" onClick={editByIdData}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
