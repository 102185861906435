import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { showError, showToast } from "../../constants/toasts";
import {
  useEditUserByIdMutation,
  useLazyGetUsersByIdQuery,
} from "../../services/user";
import EditPassportModal from "../../Modals/EditPassportModal";
import PassportModal from "../../Modals/passportModal";
import { errorToast } from "../../helpers";
import { UploadMedia } from "../../utils/uploadMedia";
import EditVehicleDetailModal from "../../Modals/EditVehicleDetailModal";
import EditTicketModal from "../../Modals/EditTicketModal";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import PhoneInput from "react-phone-input-2";
import { useGetCitiesQuery } from "../../services/profile";
import Loader from "../../constants/Loader";
interface UserData {
  tripCount: number;
  orderDelivered: number;
  onTimeDelivery: number;
  DeliverQuality: number;
  user: User;
  lastShipment: LastShipment;
  rating: number;
  ratingCount: number;
  travelCount: number;
  message: string;
}

interface User {
  personVerification: PersonVerification;
  fullName: string;
  firstName: string;
  lastName: string;
  phone: string;
  image: string;
  _id: string;
  gender: string;
  countryCode: string;
  email: string;
  id: string;
}

interface PersonVerification {
  profileImage: string;
  idCard: string;
  residenceCard: string;
  residenceType: number;
}

const countryOptions = ["India", "Nepal"];

interface LastShipment {
  vehicleDetail: VehicleDetail;
  currentAddress: CurrentAddress;
  personVerification: PersonVerification2;
  shipmentDetail: ShipmentDetail;
  multiCity: boolean;
  ticket: any[];
  adminStatus: number;
  shipmentStatus: number;
  rejectionReason: any;
  cargoType: number[];
  drugsVolunteer: boolean;
  shipmentId: string;
  status: boolean;
  additionalShipment: boolean;
  isBlocked: boolean;
  isDeleted: boolean;
  _id: string;
  travelType: number;
  travelDetail: TravelDetail[];
  destinationAddress: DestinationAddress[];
  userId: string;
  type: number;
  packageDetail: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  drugsCertification: number;
  finalPaymentCurrency: number;
  paymentArray: PaymentArray;
  paymentMode: number;
  paymentType: number;
}

interface VehicleDetail {
  vehicleReg: string;
  front: string;
  side: string;
  back: string;
}

interface CurrentAddress {
  city: string;
  district: string;
  square: string;
  houseNumber: string;
  countryCode: string;
  phone: string;
  whatsappNumber: boolean;
  address: string;
}

interface PersonVerification2 {
  profileImage: string;
  idCard: string;
  residenceCard: string;
  residenceType: number;
}

interface ShipmentDetail {
  quantity: number;
  totalWeight: any;
  photo: string;
  front: string;
  side: string;
  back: string;
  packagingServiceNeeded: boolean;
}

interface TravelDetail {
  departingFrom: string;
  destination: string;
  _id: string;
  departureDate: string;
  arrivalDate: string;
  availableWeight: number;
  travelNumber: number;
}

interface DestinationAddress {
  city: string;
  district: string;
  square: string;
  houseNumber: string;
  countryCode: string;
  phone: string;
  whatsappNumber: boolean;
  remindMe: boolean;
  address: string;
  _id: string;
  deliveryDate: string;
  deliveryTime: string;
  travelNumber: number;
}

interface PaymentArray {
  bankName: string;
  accHolderName: string;
  accNumber: string;
}
let travelMin: any = [];
let departureMin: any = [];
const EditProfile = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [idData, setIdData] = useState({
    idCard: "",
    residenceCard: "",
  });
  const [selectField1, setSelectField1] = React.useState("default");
  const handleChange1 = (event: SelectChangeEvent) => {
    setSelectField1(event.target.value as string);
  };
  const getTravelTypeName = (travelType: number) => {
    if (travelType == 1) {
      return "Private Vehicle";
    } else if (travelType == 2) {
      return "Airlines";
    } else if (travelType == 3) {
      return "Voyage";
    } else if (travelType == 4) {
      return "Railway";
    } else if (travelType == 5) {
      return "Public Road";
    } else {
      return "";
    }
  };
  const [vehicleData, setVehicleData] = useState({
    front: "",
    side: "",
    back: "",
    vehicleReg: "",
  });
  const [selectField2, setSelectField2] = React.useState("default");
  const handleChange2 = (event: SelectChangeEvent) => {
    setSelectField2(event.target.value as string);
  };
  const [selectField3, setSelectField3] = React.useState("default");
  const handleChange3 = (event: SelectChangeEvent) => {
    setSelectField3(event.target.value as string);
  };
  const [vehicleModal, setVehicleModal] = React.useState(false);
  const closeVehicleModal = () => {
    setVehicleModal(false);
  };
  const [ticketModal, setTicketModal] = React.useState(false);
  const closeTicketModal = () => {
    setTicketModal(false);
  };

  const [travelList, setTravelList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  function handleClose() {
    setOpen(false);
  }
  const [ticket, setTicket] = useState([]);
  const [userData, setUserData] = React.useState<UserData | undefined>();
  const [getUserbyId]: any = useLazyGetUsersByIdQuery();
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneToDisplay, setPhoneToDisplay] = useState("");
  const handleChangePhone = (phone: any, country: any) => {
    setPhone(phone?.replace(country.dialCode, ""));
    setCountryCode(country.dialCode);
    setPhoneToDisplay(phone);
  };
  const [phoneData, setPhoneData] = useState<string[]>([]);
  const [countryCodeData, setCountryCodeData] = useState<string[]>([]);
  const [phoneToDisplayData, setPhoneToDisplayData] = useState<string[]>([]);
  const [departureDate, setDepartureDate] = useState<any>([]);

  const [departureCity, setDepartureCity] = useState<any>([]);
  const [userAddress, setUserAddress] = useState([
    {
      address: "Saudi Arabia",
      district: "Ggg",
      houseNumber: "99",
      square: "Ffff",
    },
  ]);
  const [currentUserAddress, setCurrentUserAddress] = useState({
    address: "Saudi Arabia",
    district: "Ggg",
    houseNumber: "99",
    square: "Ffff",
  });
  const handleChangePhoneData = (phone: any, country: any, index: number) => {
    setPhoneData((prevData: any) => {
      return prevData?.map((data: any, i: number) => {
        if (index == i) {
          return phone?.replace(country.dialCode, "");
        }
        return data;
      });
    });
    setCountryCodeData((prevData: any) => {
      return prevData?.map((data: any, i: number) => {
        if (index == i) {
          return country.dialCode;
        }
        return data;
      });
    });
    setPhoneToDisplayData((prevData: any) => {
      return prevData?.map((data: any, i: number) => {
        if (index == i) {
          return phone;
        }
        return data;
      });
    });
  };

  const getData = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await getUserbyId({ id: id }).unwrap();
      if (response.statusCode == 200) {
        formik.setFieldValue("firstName", response?.data?.user?.firstName);
        formik.setFieldValue("lastName", response?.data?.user?.lastName);
        formik.setFieldValue(
          "shipmentId",
          response?.data?.lastShipment?.travelId
            ? response?.data?.lastShipment?.travelId
            : "-"
        );
        formik.setFieldValue("travelCount", response?.data?.travelCount);
        formik.setFieldValue("email", response?.data?.user?.email);
        formik.setFieldValue(
          "gender",
          response?.data?.user?.gender
            ? parseInt(response?.data?.user?.gender)
            : "default"
        );
        formik.setFieldValue(
          "residencyType",
          response?.data?.user?.personVerification?.residenceType
        );
        formik.setFieldValue(
          "currentCity",
          response?.data?.lastShipment?.currentAddress?.city
        );
        setCurrentUserAddress({
          address: response?.data?.lastShipment?.currentAddress?.address,
          district: response?.data?.lastShipment?.currentAddress?.district,
          houseNumber:
            response?.data?.lastShipment?.currentAddress?.houseNumber,
          square: response?.data?.lastShipment?.currentAddress?.square,
        });
        // formik.setFieldValue(
        //   "currentCountry",
        //   response?.data?.lastShipment?.currentAddress?.district
        // );
        // formik.setFieldValue(
        //   "currentAddress",
        //   response?.data?.lastShipment?.currentAddress?.address
        // );
        formik.setFieldValue(
          "departureCity",
          response?.data?.lastShipment?.travelDetail?.[0]?.departingFrom
        );
        formik.setFieldValue(
          "departureDate",
          response?.data?.lastShipment?.travelDetail?.[0]?.departureDate.slice(
            0,
            10
          )
        );
        formik.setFieldValue(
          "travelType",
          response?.data?.lastShipment?.travelType
        );
        setIdData({
          idCard: response?.data?.lastShipment?.personVerification?.idCard,
          residenceCard:
            response?.data?.lastShipment?.personVerification?.residenceCard,
        });
        setPhone(response?.data?.user?.phone);
        setCountryCode(response?.data?.user?.countryCode);
        setPhoneToDisplay(
          response?.data?.user?.countryCode + response?.data?.user?.phone
        );
        setTicket(response?.data?.lastShipment?.ticket || []);
        const temp = [];
        const tempPhone = [];
        const tempCountryCode = [];
        const tempPhoneToDisplay = [];
        const tempTravelType = [];
        const tempDepartureCity = [];
        const tempDepartureDate = [];
        const tempUserAddress = [];
        for (let key in response?.data?.lastShipment?.travelDetail) {
          tempUserAddress?.push({
            address:
              response?.data?.lastShipment?.destinationAddress?.[key]?.address,
            district:
              response?.data?.lastShipment?.destinationAddress?.[key]?.district,
            houseNumber:
              response?.data?.lastShipment?.destinationAddress?.[key]
                ?.houseNumber,
            square:
              response?.data?.lastShipment?.destinationAddress?.[key]?.square,
          });
          tempDepartureCity.push(
            response?.data?.lastShipment?.travelDetail?.[key]?.departingFrom
          );
          tempDepartureDate.push(
            response?.data?.lastShipment?.travelDetail?.[
              key
            ]?.departureDate?.slice(0, 10)
          );
          tempPhone.push(
            response?.data?.lastShipment?.destinationAddress?.[key]?.phone
          );
          tempCountryCode.push(
            response?.data?.lastShipment?.destinationAddress?.[key]?.countryCode
          );
          tempPhoneToDisplay.push(
            response?.data?.lastShipment?.destinationAddress?.[key]
              .countryCode +
              response?.data?.lastShipment?.destinationAddress?.[key]?.phone
          );
          temp.push({
            phone:
              response?.data?.lastShipment?.destinationAddress?.[key]?.phone,
            countryCode:
              response?.data?.lastShipment?.destinationAddress?.[key]
                ?.countryCode,
            phoneToDisplay:
              response?.data?.lastShipment?.destinationAddress?.[key]
                .countryCode +
              response?.data?.lastShipment?.destinationAddress?.[key]?.phone,
            destinationAddress:
              response?.data?.lastShipment?.travelDetail?.[key]?.destination,
            destinationCity:
              response?.data?.lastShipment?.destinationAddress?.[key]?.city,
            destinationDate: response?.data?.lastShipment?.travelDetail[
              key
            ]?.arrivalDate?.slice(0, 10),
            availableWeight:
              response?.data?.lastShipment?.travelDetail?.[key]
                ?.availableWeight,
          });
          tempTravelType.push(
            response?.data?.lastShipment?.travelDetail?.[key].travelType
          );
        }
        setUserAddress(tempUserAddress);
        setDepartureCity(tempDepartureCity);
        setDepartureDate(tempDepartureDate);
        setTravelType(tempTravelType);
        setTravelList(temp);
        travelMin = temp;
        departureMin = tempDepartureDate;
        setPhoneData(tempPhone);
        setCountryCodeData(tempCountryCode);
        setPhoneToDisplayData(tempPhoneToDisplay);
        setVehicleData(response?.data?.lastShipment?.vehicleDetail);
        setUserData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
      setIsLoading(false);
    }
  };
  // const {
  //   isLoading: loader,
  //   isError,
  //   data: placesData,
  //   isSuccess,
  // } = useGetCitiesQuery({});
  // if (isError) {
  //   showError("Something went wrong with places api");
  // }
  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, []);
  // const [countries, setCountries] = useState<any>([]);
  // const [places, setPlaces] = useState([]);
  // useEffect(() => {
  //   if (isSuccess && placesData) {
  //     const res = placesData?.data;
  //     setPlaces(placesData?.data);
  //     setCountries(
  //       Array.from(
  //         new Set(
  //           placesData?.data
  //             ?.map((data: any) => [data.country, data.country_ar])
  //             .flat()
  //         )
  //       ).sort()
  //     );
  //   }
  // }, [isSuccess, placesData]);

  const [updateData] = useEditUserByIdMutation();
  const [travelType, setTravelType] = useState<number[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      shipmentId: "",
      travelCount: "",
      email: "",
      gender: 1,
      residencyType: 1,
      currentCity: "",
      travelType: 1,
    },
    onSubmit: async (values) => {
      if (travelType?.some((data) => data == 1)) {
        if (!vehicleData?.back) {
          showError("Private Vehicle back image not added");
          return;
        }
        if (!vehicleData?.front) {
          showError("Private Vehicle front image not added");
          return;
        }
        if (!vehicleData?.side) {
          showError("Private Vehicle side image not added");
          return;
        }
        if (!vehicleData?.vehicleReg) {
          showError("Private Vehicle registration not added");
          return;
        }
      }
      if (travelType?.some((data) => data > 1)) {
        if (!ticket.length) {
          showError("Travel ticket not added");
          return;
        }
      }

      const tempTravelDetails = userData?.lastShipment?.travelDetail?.map(
        (data: any, index: number) => {
          return {
            ...data,
            departureDate: departureDate?.[index],
            departingFrom: departureCity?.[index],
            destination: travelList[index]?.destinationAddress,
            availableWeight: travelList[index]?.availableWeight,
            travelType: travelType[index],
            arrivalDate: travelList?.[index]?.destinationDate,
          };
        }
      );
      // if(!tempTravelDetails?.length)
      //     {
      //         tempTravelDetails?.push({ departingFrom:formik.values.departureCity,
      //             departureDate:(new Date(formik.values.departureDate).toISOString()),
      //             availableWeight:travelList[0]?.availableWeight,
      //             destination:travelList[0]?.destinationAddress,
      //     })
      //     }
      const tempDestinationAddress =
        userData?.lastShipment?.destinationAddress.map(
          (data: DestinationAddress, index: number) => {
            return {
              ...data,
              square: userAddress?.[index]?.square,
              address: userAddress?.[index]?.address,
              district: userAddress?.[index]?.district,
              houseNumber: userAddress?.[index]?.houseNumber,
              city: travelList[index]?.destinationCity,
              phone: phoneData?.[index],
              countryCode: countryCodeData?.[index]?.includes("+")
                ? countryCodeData?.[index]
                : "+" + countryCodeData?.[index],
            };
          }
        );
      const body = {
        phone: phone,
        countryCode: countryCode?.includes("+")
          ? countryCode
          : "+" + countryCode,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        email: values.email,
        // personVerification: { residenceType: values.residencyType },
        // residenceType: values.residencyType,
        lastShipment: {
          _id: userData?.lastShipment?._id,
          vehicleDetail: travelType?.some((data) => data == 1)
            ? vehicleData
            : null,
          currentAddress: {
            ...userData?.lastShipment?.currentAddress,
            ...currentUserAddress,
            city: formik.values.currentCity,
          },
          travelDetail: tempTravelDetails,
          travelType: formik.values.travelType,
          ticket: travelType.some((data) => data > 1) ? ticket : null,
          destinationAddress: tempDestinationAddress,
        },
      };

      try {
        const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateData({
          id: `${id}`,
          body: encryptedData,
        }).unwrap();
        if (response?.statusCode == 200) {
          showToast(response?.message || "User Updated Successfully");
          // navigate("/profile-detail/"+id);
          navigate(state ? state : "/profile-detail/" + id, { replace: true });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Edit Profile Detail</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Registrations - Edit Profile Detail
        </p>
      </div>

      <div className="cards">
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="gap_p">
            <div className="control_group w_50">
              <label>TR</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                disabled
                name="shipmentId"
                value={formik.values.shipmentId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>E-mail</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                name="email"
                // disabled
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>First Name</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                // disabled
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>Last Name</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                // disabled
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* <div className="control_group w_50">
              <label>No of Travel</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                disabled
                name="travelCount"
                value={formik.values.travelCount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div> */}

            <div className="control_group w_50">
              <label>Gender</label>
              <Select
                fullWidth
                labelId="gender"
                id="gender"
                // disabled
                name="gender"
                value={formik.values.gender}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem disabled value="default">
                  Select
                </MenuItem>
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </div>
            <div className="control_group w_50">
              <label>Mobile</label>
              {/* <TextField
                                    variant="outlined"
                                    placeholder="Enter here"
                                    fullWidth
                                    hiddenLabel
                                    name="mobile"
                                    disabled
                                    value={phoneToDisplay}
                                    onChange={(e)=>setPhoneToDisplay(e.target.value)}
                                /> */}
              <PhoneInput
                specialLabel=""
                country={"us"}
                placeholder="0 (000) 000-000"
                enableSearch={true}
                // disabled
                inputStyle={{
                  color: "black",
                  backgroundColor: "white",
                  width: "100%",
                }}
                value={phoneToDisplay}
                // onChange={(value) => formik.setFieldValue("phone", value)}
                onChange={(phone: any, country: any) =>
                  handleChangePhone(phone, country)
                }
              />
            </div>
            <div className="control_group w_50">
              <label>Residency Type</label>
              <Select
                fullWidth
                labelId="residencyType"
                id="residencyType"
                name="residencyType"
                disabled
                value={formik.values.residencyType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem disabled value="default">
                  Select
                </MenuItem>
                <MenuItem value={1}>Residence</MenuItem>
                <MenuItem value={2}>Visitor</MenuItem>
                <MenuItem value={3}>Citizen</MenuItem>
              </Select>
            </div>

            <div className="control_group w_50">
              <label>Current House Number</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                name="currentCountry"
                value={currentUserAddress?.houseNumber}
                onChange={(e) =>
                  setCurrentUserAddress((prev: any) => ({
                    ...prev,
                    houseNumber: e.target.value,
                  }))
                }
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>Current Street</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                name="currentCountry"
                value={currentUserAddress?.square}
                onChange={(e) =>
                  setCurrentUserAddress((prev: any) => ({
                    ...prev,
                    square: e.target.value,
                  }))
                }
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>Current City</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                disabled
                hiddenLabel
                name="currentCity"
                value={formik.values.currentCity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>Current District</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                name="currentCountry"
                value={currentUserAddress?.district}
                onChange={(e) =>
                  setCurrentUserAddress((prev: any) => ({
                    ...prev,
                    district: e.target.value,
                  }))
                }
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="control_group w_50">
              <label>Current Country</label>
              <TextField
                variant="outlined"
                placeholder="Enter here"
                fullWidth
                hiddenLabel
                disabled
                name="currentCountry"
                value={currentUserAddress?.address}
                onChange={(e) =>
                  setCurrentUserAddress((prev: any) => ({
                    ...prev,
                    address: e.target.value,
                  }))
                }
                onBlur={formik.handleBlur}
              />
            </div>

            {/* <div className="control_group w_50">
                                <label>Way of Travel</label>
                                <Select
                                    fullWidth
                                    labelId="wayOfTravel"
                                    id="wayOfTravel"
                                    name="travelType"
                                    value={formik.values.travelType}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                >
                                    <MenuItem disabled value="default">Select</MenuItem>
                                    <MenuItem value={1}>Private Vehicle</MenuItem>
                                    <MenuItem value={2}>Airline</MenuItem>
                                    <MenuItem value={3}>Voyage</MenuItem>
                                    <MenuItem value={4}>Railway</MenuItem>
                                    <MenuItem value={5}>Public Road</MenuItem>
                                </Select>
                            </div> */}
            <div
              onClick={() => {
                setOpen(true);
              }}
              className="control_group w_50"
            >
              <label>Passport /ID</label>
              <div className="upload_input">
                <TextField
                  variant="outlined"
                  placeholder="View Passport Id"
                  fullWidth
                  hiddenLabel
                />
                <p>
                  View Passport Id{" "}
                  <img src="/static/images/paperClip_icon.svg" alt="Icon" />
                </p>
              </div>
            </div>
            {travelType?.some((data) => data == 1) && (
              <div className="control_group w_50">
                <label>Vehicle Reg</label>
                <div
                  onClick={() => {
                    setVehicleModal(true);
                  }}
                  className="upload_input"
                >
                  <TextField
                    variant="outlined"
                    placeholder="Attach link"
                    fullWidth
                    hiddenLabel
                    // disabled
                    // type="file"
                  />
                  <p>
                    Edit Vehicle Reg
                    <img src="/static/images/paperClip_icon.svg" alt="Icon" />
                  </p>
                </div>
              </div>
            )}

            {travelType.some((data) => data > 1) && (
              <div className="control_group w_50">
                <label>Ticket</label>
                <div
                  onClick={() => {
                    setTicketModal(true);
                  }}
                  className="upload_input"
                >
                  <TextField
                    variant="outlined"
                    placeholder="Attach link"
                    fullWidth
                    hiddenLabel
                    // disabled
                  />
                  <p>
                    Edit Ticket
                    <img src="/static/images/paperClip_icon.svg" alt="Icon" />
                  </p>
                </div>
              </div>
            )}

            {travelList?.map((data: any, index: number) => {
              return (
                <>
                  {userAddress?.[index] && (
                    <div className="control_group w_50">
                      <label>House Number({index + 1})</label>
                      <TextField
                        variant="outlined"
                        placeholder="Enter here"
                        fullWidth
                        hiddenLabel
                        name="houseNumber"
                        value={userAddress?.[index]?.houseNumber}
                        onChange={(e) => {
                          setUserAddress((prev: any) =>
                            prev.map((data: any, index2: number) =>
                              index == index2
                                ? { ...data, houseNumber: e.target.value }
                                : data
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  )}
                  {userAddress?.[index] && (
                    <div className="control_group w_50">
                      <label>Street ({index + 1})</label>
                      <TextField
                        variant="outlined"
                        placeholder="Enter here"
                        fullWidth
                        hiddenLabel
                        name="square"
                        value={userAddress?.[index]?.square}
                        onChange={(e) => {
                          setUserAddress((prev: any) =>
                            prev.map((data: any, index2: number) =>
                              index == index2
                                ? { ...data, square: e.target.value }
                                : data
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  )}
                  {userAddress?.[index] && (
                    <div className="control_group w_50">
                      <label>District ({index + 1})</label>
                      <TextField
                        variant="outlined"
                        placeholder="Enter here"
                        fullWidth
                        hiddenLabel
                        name="district"
                        value={userAddress?.[index]?.district}
                        onChange={(e) => {
                          setUserAddress((prev: any) =>
                            prev.map((data: any, index2: number) =>
                              index == index2
                                ? { ...data, district: e.target.value }
                                : data
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  )}
                  {userAddress?.[index] && (
                    <div className="control_group w_50">
                      <label>Country ({index + 1})</label>
                      <TextField
                        variant="outlined"
                        placeholder="Enter here"
                        fullWidth
                        hiddenLabel
                        disabled
                        name="address"
                        value={userAddress?.[index]?.address}
                        onChange={(e) => {
                          setUserAddress((prev: any) =>
                            prev.map((data: any, index2: number) =>
                              index == index2
                                ? { ...data, address: e.target.value }
                                : data
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  )}
                  {
                    <div className="control_group w_50">
                      <label>Departure City ({index + 1})</label>
                      <TextField
                        variant="outlined"
                        placeholder="Enter here"
                        fullWidth
                        hiddenLabel
                        disabled
                        name="departureCity"
                        value={departureCity[index]}
                        onChange={(e) => {
                          setDepartureCity((prev: any) =>
                            prev.map((data: any, index2: number) =>
                              index == index2 ? e.target.value : data
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  }
                  {
                    <div className="control_group w_50">
                      <label>Departure Date ({index + 1})</label>
                      <TextField
                        variant="outlined"
                        placeholder="Enter here"
                        fullWidth
                        hiddenLabel
                        name="departureDate"
                        type="date"
                        value={departureDate[index]}
                        onChange={(e) => {
                          setDepartureDate((prev: any) =>
                            prev.map((data: any, index2: number) =>
                              index == index2 ? e.target.value : data
                            )
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  }
                  <div className="control_group w_50">
                    <label>Mobile ({index + 1})</label>
                    {/* <TextField
                                        variant="outlined"
                                        placeholder="Enter here"
                                        fullWidth
                                        hiddenLabel
                                        value={data?.phoneToDisplay}
                                        onChange={(e:any)=>setTravelList((prevData:any)=>{
                                         return   prevData?.map((data:any,index2:number)=>{
                                                if(index2==index)
                                                {
                                                    return {...data,phoneToDisplay:e.target.value}
                                                }
                                                    return data;
                                            })
                                        })}
                                    /> */}
                    <PhoneInput
                      specialLabel=""
                      country={"us"}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      value={phoneToDisplayData[index]}
                      // onChange={(value) => formik.setFieldValue("phone", value)}
                      onChange={(phone: any, country: any) =>
                        handleChangePhoneData(phone, country, index)
                      }
                    />
                  </div>

                  <div className="control_group w_50">
                    <label>Destination Address ({index + 1})</label>
                    <TextField
                      variant="outlined"
                      placeholder="Enter here"
                      fullWidth
                      hiddenLabel
                      value={data?.destinationAddress}
                      onChange={(e: any) =>
                        setTravelList((prevData: any) => {
                          return prevData?.map((data: any, index2: number) => {
                            if (index == index2) {
                              return {
                                ...data,
                                destinationAddress: e.target.value,
                              };
                            }
                            return data;
                          });
                        })
                      }
                    />
                  </div>
                  <div className="control_group w_50">
                    <label>Destination/Arrival City ({index + 1})</label>
                    <TextField
                      variant="outlined"
                      disabled
                      placeholder="Enter here"
                      fullWidth
                      hiddenLabel
                      value={data?.destinationCity}
                      onChange={(e: any) =>
                        setTravelList((prevData: any) => {
                          return prevData?.map((data: any, index2: number) => {
                            if (index == index2) {
                              return {
                                ...data,
                                destinationCity: e.target.value,
                              };
                            }
                            return data;
                          });
                        })
                      }
                    />
                  </div>
                  <div className="control_group w_50">
                    <label>Arrival Date ({index + 1})</label>
                    <TextField
                      variant="outlined"
                      placeholder="Enter here"
                      fullWidth
                      hiddenLabel
                      type="date"
                      value={data?.destinationDate}
                      onChange={(e: any) =>
                        setTravelList((prevData: any) => {
                          return prevData?.map((data: any, index2: number) => {
                            if (index == index2) {
                              return {
                                ...data,
                                destinationDate: e.target.value,
                              };
                            }
                            return data;
                          });
                        })
                      }
                    />
                  </div>
                  <div className="control_group w_50">
                    <label>Available weights ({index + 1})</label>
                    <TextField
                      variant="outlined"
                      placeholder="Enter here"
                      fullWidth
                      hiddenLabel
                      value={data?.availableWeight}
                      onChange={(e: any) =>
                        setTravelList((prevData: any) => {
                          return prevData?.map((data: any, index2: number) => {
                            if (index == index2) {
                              return {
                                ...data,
                                availableWeight: e.target.value,
                              };
                            }
                            return data;
                          });
                        })
                      }
                    />
                  </div>
                  <div className="control_group w_50">
                    <label>Way of Travel ({index + 1})</label>
                    <Select
                      fullWidth
                      labelId="wayOfTravel"
                      id="wayOfTravel"
                      name="travelType"
                      disabled
                      value={travelType[index]}
                      onChange={(e) => {
                        setTravelType((prev: any) =>
                          prev.map((travelData: number, travelIndex: number) =>
                            index == travelIndex ? e.target.value : travelData
                          )
                        );
                      }}
                      onBlur={formik.handleBlur}
                    >
                      <MenuItem disabled value="default">
                        Select
                      </MenuItem>
                      <MenuItem value={1}>Private Vehicle</MenuItem>
                      <MenuItem value={2}>Airline</MenuItem>
                      <MenuItem value={3}>Voyage</MenuItem>
                      <MenuItem value={4}>Railway</MenuItem>
                      <MenuItem value={5}>Public Road</MenuItem>
                    </Select>
                  </div>
                </>
              );
            })}
          </div>
          <div className="form_btn end">
            <Button
              className="btn btn_secondary"
              type="submit"
              // onClick={()=>navigate(state?state:"/profile-detail/"+id)}
            >
              Save Changes
            </Button>
          </div>
        </form>
      </div>
      <PassportModal
        open={open}
        onClose={handleClose}
        setOpen={setOpen}
        idData={idData}
      />
      <EditTicketModal
        open={ticketModal}
        setOpen={setTicketModal}
        onClose={closeTicketModal}
        ticket={ticket}
        wayOfTravel={getTravelTypeName(formik.values.travelType)}
        setTicket={setTicket}
      />
      {/* <EditPassportModal open={open} setOpen={setOpen} onClose={handleClose} idData={idData} setIdData={setIdData}/> */}
      <EditVehicleDetailModal
        open={vehicleModal}
        setOpen={setVehicleModal}
        onClose={closeVehicleModal}
        vehicleData={vehicleData}
        setVehicleData={setVehicleData}
      />
    </>
  );
  // return (
  //   <>
  //     <Loader isLoad={loader} />
  //     <div className="main_title">
  //       <h1>Edit Profile Detail</h1>
  //       <p>
  //         <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
  //         Registrations - Edit Profile Detail
  //       </p>
  //     </div>

  //     <div className="cards">
  //       <form className="form" onSubmit={formik.handleSubmit}>
  //         <div className="gap_p">
  //           <div className="control_group w_50">
  //             <label>Name</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               disabled
  //               name="fullName"
  //               value={formik.values.fullName}
  //               onChange={formik.handleChange}
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>TR</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               disabled
  //               name="shipmentId"
  //               value={formik.values.shipmentId}
  //               onChange={formik.handleChange}
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>No of Travel</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               disabled
  //               name="travelCount"
  //               value={formik.values.travelCount}
  //               onChange={formik.handleChange}
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>E-mail</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               name="email"
  //               disabled
  //               value={formik.values.email}
  //               onChange={formik.handleChange}
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>Gender</label>
  //             <Select
  //               fullWidth
  //               labelId="gender"
  //               id="gender"
  //               disabled
  //               name="gender"
  //               value={formik.values.gender}
  //               onChange={formik.handleChange}
  //               onBlur={formik.handleBlur}
  //             >
  //               <MenuItem disabled value="default">
  //                 Select
  //               </MenuItem>
  //               <MenuItem value={1}>Male</MenuItem>
  //               <MenuItem value={2}>Female</MenuItem>
  //             </Select>
  //           </div>
  //           <div className="control_group w_50">
  //             <label>Mobile</label>
  //             {/* <TextField
  //                               variant="outlined"
  //                               placeholder="Enter here"
  //                               fullWidth
  //                               hiddenLabel
  //                               name="mobile"
  //                               disabled
  //                               value={phoneToDisplay}
  //                               onChange={(e)=>setPhoneToDisplay(e.target.value)}
  //                           /> */}
  //             <PhoneInput
  //               specialLabel=""
  //               country={"us"}
  //               placeholder="0 (000) 000-000"
  //               enableSearch={true}
  //               disabled
  //               inputStyle={{
  //                 color: "grey",
  //                 backgroundColor: "white",
  //                 width: "100%",
  //               }}
  //               value={phoneToDisplay}
  //               // onChange={(value) => formik.setFieldValue("phone", value)}
  //               onChange={(phone: any, country: any) =>
  //                 handleChangePhone(phone, country)
  //               }
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>Residency Type</label>
  //             <Select
  //               fullWidth
  //               labelId="residencyType"
  //               id="residencyType"
  //               name="residencyType"
  //               disabled
  //               value={formik.values.residencyType}
  //               onChange={formik.handleChange}
  //               onBlur={formik.handleBlur}
  //             >
  //               <MenuItem disabled value="default">
  //                 Select
  //               </MenuItem>
  //               <MenuItem value={1}>Residence</MenuItem>
  //               <MenuItem value={2}>Visitor</MenuItem>
  //               <MenuItem value={3}>Citizen</MenuItem>
  //             </Select>
  //           </div>

  //           <div className="control_group w_50">
  //             <label>Current House Number</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               name="currentCountry"
  //               value={currentUserAddress?.houseNumber}
  //               onChange={(e) =>
  //                 setCurrentUserAddress((prev: any) => ({
  //                   ...prev,
  //                   houseNumber: e.target.value,
  //                 }))
  //               }
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>Current Street</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               name="currentCountry"
  //               value={currentUserAddress?.square}
  //               onChange={(e) =>
  //                 setCurrentUserAddress((prev: any) => ({
  //                   ...prev,
  //                   square: e.target.value,
  //                 }))
  //               }
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>

  //           <div className="control_group w_50">
  //             <label>Current City</label>
  //             <Autocomplete
  //               options={countryOptions}
  //               value={formik.values.currentCity || ""}
  //               onChange={(event, newValue) => {
  //                 formik.setFieldValue("currentCity", newValue);
  //               }}
  //               onBlur={formik.handleBlur}
  //               renderInput={(params) => (
  //                 <TextField
  //                   {...params}
  //                   variant="outlined"
  //                   placeholder="Enter here"
  //                   fullWidth
  //                   hiddenLabel
  //                   name="currentCity"
  //                   error={
  //                     formik.touched.currentCity &&
  //                     Boolean(formik.errors.currentCity)
  //                   }
  //                   helperText={
  //                     formik.touched.currentCity && formik.errors.currentCity
  //                   }
  //                 />
  //               )}
  //             />
  //           </div>

  //           <div className="control_group w_50">
  //             <label>Current District</label>
  //             <TextField
  //               variant="outlined"
  //               placeholder="Enter here"
  //               fullWidth
  //               hiddenLabel
  //               name="currentCountry"
  //               value={currentUserAddress?.district}
  //               onChange={(e) =>
  //                 setCurrentUserAddress((prev: any) => ({
  //                   ...prev,
  //                   district: e.target.value,
  //                 }))
  //               }
  //               onBlur={formik.handleBlur}
  //             />
  //           </div>
  //           <div className="control_group w_50">
  //             <label>Current Country</label>
  //             <Autocomplete
  //               freeSolo
  //               options={countries}
  //               value={currentUserAddress?.address || ""}
  //               onChange={(event, newValue) => {
  //
  //                 setCurrentUserAddress((prev: any) => ({
  //                   ...prev,
  //                   address: newValue,
  //                 }));
  //               }}
  //               onInputChange={(event, inputValue) => {
  //
  //                 setCurrentUserAddress((prev: any) => ({
  //                   ...prev,
  //                   address: inputValue,
  //                 }));
  //               }}
  //               onBlur={() => {
  //                 if (
  //                   !countries?.some(
  //                     (data: any) => data === currentUserAddress?.address
  //                   )
  //                 ) {
  //                   showError("Please select country from given options");
  //                   setCurrentUserAddress((prev: any) => ({
  //                     ...prev,
  //                     address: "",
  //                   }));
  //                 }
  //               }}
  //               renderInput={(params) => (
  //                 <TextField
  //                   {...params}
  //                   variant="outlined"
  //                   placeholder="Enter here"
  //                   fullWidth
  //                   hiddenLabel
  //                   name="currentCountry"
  //                 />
  //               )}
  //             />
  //           </div>

  //           {/* <div className="control_group w_50">
  //                           <label>Way of Travel</label>
  //                           <Select
  //                               fullWidth
  //                               labelId="wayOfTravel"
  //                               id="wayOfTravel"
  //                               name="travelType"
  //                               value={formik.values.travelType}
  //                               onChange={formik.handleChange}
  //                               onBlur={formik.handleBlur}
  //                           >
  //                               <MenuItem disabled value="default">Select</MenuItem>
  //                               <MenuItem value={1}>Private Vehicle</MenuItem>
  //                               <MenuItem value={2}>Airline</MenuItem>
  //                               <MenuItem value={3}>Voyage</MenuItem>
  //                               <MenuItem value={4}>Railway</MenuItem>
  //                               <MenuItem value={5}>Public Road</MenuItem>
  //                           </Select>
  //                       </div> */}
  //           <div
  //             onClick={() => {
  //               setOpen(true);
  //             }}
  //             className="control_group w_50"
  //           >
  //             <label>Passport /ID</label>
  //             <div className="upload_input">
  //               <TextField
  //                 variant="outlined"
  //                 placeholder="View Passport Id"
  //                 fullWidth
  //                 hiddenLabel
  //               />
  //               <p>
  //                 View Passport Id{" "}
  //                 <img src="/static/images/paperClip_icon.svg" alt="Icon" />
  //               </p>
  //             </div>
  //           </div>
  //           {travelType?.some((data) => data == 1) && (
  //             <div className="control_group w_50">
  //               <label>Vehicle Reg</label>
  //               <div
  //                 onClick={() => {
  //                   setVehicleModal(true);
  //                 }}
  //                 className="upload_input"
  //               >
  //                 <TextField
  //                   variant="outlined"
  //                   placeholder="Attach link"
  //                   fullWidth
  //                   hiddenLabel
  //                   // disabled
  //                   // type="file"
  //                 />
  //                 <p>
  //                   Edit Vehicle Reg
  //                   <img src="/static/images/paperClip_icon.svg" alt="Icon" />
  //                 </p>
  //               </div>
  //             </div>
  //           )}

  //           {travelType.some((data) => data > 1) && (
  //             <div className="control_group w_50">
  //               <label>Ticket</label>
  //               <div
  //                 onClick={() => {
  //                   setTicketModal(true);
  //                 }}
  //                 className="upload_input"
  //               >
  //                 <TextField
  //                   variant="outlined"
  //                   placeholder="Attach link"
  //                   fullWidth
  //                   hiddenLabel
  //                   // disabled
  //                 />
  //                 <p>
  //                   Edit Ticket
  //                   <img src="/static/images/paperClip_icon.svg" alt="Icon" />
  //                 </p>
  //               </div>
  //             </div>
  //           )}

  //           {travelList?.map((data: any, index: number) => {
  //             return (
  //               <>
  //                 {userAddress?.[index] && (
  //                   <div className="control_group w_50">
  //                     <label>House Number({index + 1})</label>
  //                     <TextField
  //                       variant="outlined"
  //                       placeholder="Enter here"
  //                       fullWidth
  //                       hiddenLabel
  //                       name="houseNumber"
  //                       value={userAddress?.[index]?.houseNumber}
  //                       onChange={(e) => {
  //                         setUserAddress((prev: any) =>
  //                           prev.map((data: any, index2: number) =>
  //                             index == index2
  //                               ? { ...data, houseNumber: e.target.value }
  //                               : data
  //                           )
  //                         );
  //                       }}
  //                       onBlur={formik.handleBlur}
  //                     />
  //                   </div>
  //                 )}
  //                 {userAddress?.[index] && (
  //                   <div className="control_group w_50">
  //                     <label>Street ({index + 1})</label>
  //                     <TextField
  //                       variant="outlined"
  //                       placeholder="Enter here"
  //                       fullWidth
  //                       hiddenLabel
  //                       name="square"
  //                       value={userAddress?.[index]?.square}
  //                       onChange={(e) => {
  //                         setUserAddress((prev: any) =>
  //                           prev.map((data: any, index2: number) =>
  //                             index == index2
  //                               ? { ...data, square: e.target.value }
  //                               : data
  //                           )
  //                         );
  //                       }}
  //                       onBlur={formik.handleBlur}
  //                     />
  //                   </div>
  //                 )}
  //                 {userAddress?.[index] && (
  //                   <div className="control_group w_50">
  //                     <label>District ({index + 1})</label>
  //                     <TextField
  //                       variant="outlined"
  //                       placeholder="Enter here"
  //                       fullWidth
  //                       hiddenLabel
  //                       name="district"
  //                       value={userAddress?.[index]?.district}
  //                       onChange={(e) => {
  //                         setUserAddress((prev: any) =>
  //                           prev.map((data: any, index2: number) =>
  //                             index == index2
  //                               ? { ...data, district: e.target.value }
  //                               : data
  //                           )
  //                         );
  //                       }}
  //                       onBlur={formik.handleBlur}
  //                     />
  //                   </div>
  //                 )}
  //                 {userAddress?.[index] && (
  //                   <div className="control_group w_50">
  //                     <label>Country ({index + 1})</label>
  //                     <Autocomplete
  //                       disabled
  //                       options={countries}
  //                       value={userAddress?.[index]?.address || ""}
  //                       onChange={(event, newValue) => {
  //                         setUserAddress((prev: any) =>
  //                           prev.map((data: any, index2: number) =>
  //                             index === index2
  //                               ? { ...data, address: newValue }
  //                               : data
  //                           )
  //                         );
  //                       }}
  //                       onBlur={() => {
  //                         if (
  //                           !countries?.some(
  //                             (data: any) =>
  //                               data === userAddress?.[index]?.address
  //                           )
  //                         ) {
  //                           showError(
  //                             "Please select country from given options"
  //                           );
  //                           setUserAddress((prev: any) =>
  //                             prev.map((data: any, index2: number) =>
  //                               index === index2
  //                                 ? { ...data, address: "" }
  //                                 : data
  //                             )
  //                           );
  //                         }
  //                       }}
  //                       renderInput={(params) => (
  //                         <TextField
  //                           {...params}
  //                           variant="outlined"
  //                           placeholder="Enter here"
  //                           fullWidth
  //                           hiddenLabel
  //                           name="address"
  //                         />
  //                       )}
  //                     />
  //                   </div>
  //                 )}
  //                 {
  //                   <div className="control_group w_50">
  //                     <label>Departure City ({index + 1})</label>
  //                     <Autocomplete
  //                       options={countryOptions}
  //                       value={departureCity[index] || ""}
  //                       onChange={(event, newValue) => {
  //                         setDepartureCity((prev: any) =>
  //                           prev.map((data: any, index2: number) =>
  //                             index === index2 ? newValue : data
  //                           )
  //                         );
  //                       }}
  //                       onBlur={formik.handleBlur}
  //                       renderInput={(params) => (
  //                         <TextField
  //                           {...params}
  //                           variant="outlined"
  //                           placeholder="Enter here"
  //                           fullWidth
  //                           hiddenLabel
  //                           name="departureCity"
  //                         />
  //                       )}
  //                     />
  //                   </div>
  //                 }
  //                 {
  //                   <div className="control_group w_50">
  //                     <label>Departure Date ({index + 1})</label>
  //                     <TextField
  //                       variant="outlined"
  //                       placeholder="Enter here"
  //                       fullWidth
  //                       hiddenLabel
  //                       name="departureDate"
  //                       type="date"
  //                       value={departureDate[index]}
  //                       onChange={(e) => {
  //                         setDepartureDate((prev: any) =>
  //                           prev.map((data: any, index2: number) =>
  //                             index == index2 ? e.target.value : data
  //                           )
  //                         );
  //                       }}
  //                       InputProps={{
  //                         inputProps: {
  //                           min: new Date(
  //                             departureMin[index]
  //                           ).toLocaleDateString("en-CA"), // Set minimum date
  //                         },
  //                       }}
  //                       onBlur={formik.handleBlur}
  //                     />
  //                   </div>
  //                 }
  //                 <div className="control_group w_50">
  //                   <label>Mobile ({index + 1})</label>
  //                   {/* <TextField
  //                                   variant="outlined"
  //                                   placeholder="Enter here"
  //                                   fullWidth
  //                                   hiddenLabel
  //                                   value={data?.phoneToDisplay}
  //                                   onChange={(e:any)=>setTravelList((prevData:any)=>{
  //                                    return   prevData?.map((data:any,index2:number)=>{
  //                                           if(index2==index)
  //                                           {
  //                                               return {...data,phoneToDisplay:e.target.value}
  //                                           }
  //                                               return data;
  //                                       })
  //                                   })}
  //                               /> */}
  //                   <PhoneInput
  //                     specialLabel=""
  //                     country={"us"}
  //                     placeholder="0 (000) 000-000"
  //                     enableSearch={true}
  //                     inputStyle={{ width: "100%" }}
  //                     value={phoneToDisplayData[index]}
  //                     // onChange={(value) => formik.setFieldValue("phone", value)}
  //                     onChange={(phone: any, country: any) =>
  //                       handleChangePhoneData(phone, country, index)
  //                     }
  //                   />
  //                 </div>

  //                 <div className="control_group w_50">
  //                   <label>Destination Address ({index + 1})</label>
  //                   <TextField
  //                     variant="outlined"
  //                     placeholder="Enter here"
  //                     fullWidth
  //                     hiddenLabel
  //                     value={data?.destinationAddress}
  //                     onChange={(e: any) =>
  //                       setTravelList((prevData: any) => {
  //                         return prevData?.map((data: any, index2: number) => {
  //                           if (index == index2) {
  //                             return {
  //                               ...data,
  //                               destinationAddress: e.target.value,
  //                             };
  //                           }
  //                           return data;
  //                         });
  //                       })
  //                     }
  //                   />
  //                 </div>
  //                 <div className="control_group w_50">
  //                   <label>Destination/Arrival City ({index + 1})</label>
  //                   <Autocomplete
  //                     options={countryOptions}
  //                     value={data?.destinationCity || ""}
  //                     onChange={(event, newValue) => {
  //                       setTravelList((prevData: any) => {
  //                         return prevData?.map((item: any, index2: number) => {
  //                           if (index === index2) {
  //                             return {
  //                               ...item,
  //                               destinationCity: newValue,
  //                             };
  //                           }
  //                           return item;
  //                         });
  //                       });
  //                     }}
  //                     renderInput={(params) => (
  //                       <TextField
  //                         {...params}
  //                         variant="outlined"
  //                         placeholder="Enter here"
  //                         fullWidth
  //                         hiddenLabel
  //                       />
  //                     )}
  //                   />
  //                 </div>
  //                 <div className="control_group w_50">
  //                   <label>Arrival Date ({index + 1})</label>
  //                   <TextField
  //                     variant="outlined"
  //                     placeholder="Enter here"
  //                     fullWidth
  //                     hiddenLabel
  //                     type="date"
  //                     value={data?.destinationDate}
  //                     InputProps={{
  //                       inputProps: {
  //                         min: new Date(
  //                           travelMin?.[index]?.destinationDate
  //                         ).toLocaleDateString("en-CA"), // Set minimum date
  //                       },
  //                     }}
  //                     onChange={(e: any) =>
  //                       setTravelList((prevData: any) => {
  //                         return prevData?.map((data: any, index2: number) => {
  //                           if (index == index2) {
  //                             return {
  //                               ...data,
  //                               destinationDate: e.target.value,
  //                             };
  //                           }
  //                           return data;
  //                         });
  //                       })
  //                     }
  //                   />
  //                 </div>
  //                 <div className="control_group w_50">
  //                   <label>Available weights ({index + 1})</label>
  //                   <TextField
  //                     variant="outlined"
  //                     placeholder="Enter here"
  //                     fullWidth
  //                     hiddenLabel
  //                     value={data?.availableWeight}
  //                     onChange={(e: any) =>
  //                       setTravelList((prevData: any) => {
  //                         return prevData?.map((data: any, index2: number) => {
  //                           if (index == index2) {
  //                             return {
  //                               ...data,
  //                               availableWeight: e.target.value,
  //                             };
  //                           }
  //                           return data;
  //                         });
  //                       })
  //                     }
  //                   />
  //                 </div>
  //                 <div className="control_group w_50">
  //                   <label>Way of Travel ({index + 1})</label>
  //                   <Select
  //                     fullWidth
  //                     labelId="wayOfTravel"
  //                     id="wayOfTravel"
  //                     name="travelType"
  //                     disabled
  //                     value={travelType[index]}
  //                     onChange={(e) => {
  //                       setTravelType((prev: any) =>
  //                         prev.map((travelData: number, travelIndex: number) =>
  //                           index == travelIndex ? e.target.value : travelData
  //                         )
  //                       );
  //                     }}
  //                     onBlur={formik.handleBlur}
  //                   >
  //                     <MenuItem disabled value="default">
  //                       Select
  //                     </MenuItem>
  //                     <MenuItem value={1}>Private Vehicle</MenuItem>
  //                     <MenuItem value={2}>Airline</MenuItem>
  //                     <MenuItem value={3}>Voyage</MenuItem>
  //                     <MenuItem value={4}>Railway</MenuItem>
  //                     <MenuItem value={5}>Public Road</MenuItem>
  //                   </Select>
  //                 </div>
  //               </>
  //             );
  //           })}
  //         </div>
  //         <div className="form_btn end">
  //           <Button
  //             className="btn btn_secondary"
  //             type="submit"
  //             // onClick={()=>navigate(state?state:"/profile-detail/"+id)}
  //           >
  //             Save Changes
  //           </Button>
  //         </div>
  //       </form>
  //     </div>
  //     <PassportModal
  //       open={open}
  //       onClose={handleClose}
  //       setOpen={setOpen}
  //       idData={idData}
  //     />
  //     <EditTicketModal
  //       open={ticketModal}
  //       setOpen={setTicketModal}
  //       onClose={closeTicketModal}
  //       ticket={ticket}
  //       wayOfTravel={getTravelTypeName(formik.values.travelType)}
  //       setTicket={setTicket}
  //     />
  //     {/* <EditPassportModal open={open} setOpen={setOpen} onClose={handleClose} idData={idData} setIdData={setIdData}/> */}
  //     <EditVehicleDetailModal
  //       open={vehicleModal}
  //       setOpen={setVehicleModal}
  //       onClose={closeVehicleModal}
  //       vehicleData={vehicleData}
  //       setVehicleData={setVehicleData}
  //     />
  //   </>
  // );
};

export default EditProfile;
