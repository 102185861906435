import { IconButton, Modal } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Delete, CameraAltTwoTone } from "@mui/icons-material";
import { showError, showToast } from "../constants/toasts";
import { errorToast } from "../helpers";
import { UploadMedia } from "../utils/uploadMedia";

interface VehicleRegistrationModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  vehicleData?: {
    vehicleReg: string | null | undefined;
    side: string | null | undefined;
    front: string | null | undefined;
    back: string | null | undefined;
  };
  setVehicleData?: any;
}

export default function EditVehicleDetailModal({
  open,
  onClose,
  setOpen,
  vehicleData,
  setVehicleData,
}: VehicleRegistrationModalProps) {
  const [tempData, setTempData] = useState(vehicleData);
  useEffect(() => {
    setTempData(vehicleData);
  }, [open]);
  const [isLoading, setIsLoading] = useState(false);
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: number
  ) => {
    const files = event.target.files;
    const file = files?.length ? files[0] : null;

    try {
      if (!file) {
        return;
      }
      const allowedExtensions = ["png", "jpg", "jpeg"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();

      if (!allowedExtensions.includes(fileExtension || "")) {
        setIsLoading(false);
        showError("Invalid file format: only png, jpg images are allowed");
        return;
      }
      setIsLoading(true);
      const res = await UploadMedia(file, fileExtension);
      if (res?.statusCode === 200) {
        switch (imageType) {
          case 1:
            setTempData((prevData: any) => ({ ...prevData, front: res?.data }));
            break;
          case 2:
            setTempData((prevData: any) => ({ ...prevData, side: res?.data }));
            break;
          case 3:
            setTempData((prevData: any) => ({ ...prevData, back: res?.data }));
            break;
          case 4:
            setTempData((prevData: any) => ({
              ...prevData,
              vehicleReg: res?.data,
            }));
            break;
          default:
            showError("Invalid Image Id");
        }
      } else {
        errorToast(res?.message);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorToast("Error uploading image. Please try again.");
    }
    setIsLoading(false);
  };
  return (
    <Modal
      className="modal vehicleRegistration_modal"
      id="vehicleRegistrationModal"
      aria-labelledby="vehicleRegistration-modal-title"
      aria-describedby="vehicleRegistration-modal-description"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>{"Travel by private vehicle"}</h2>
            <p>Vehicle Registration</p>
          </div>
          {
            <div className="vehicle_img gap_m">
              <figure
                style={
                  tempData?.front
                    ? { position: "relative", marginTop: "20px" }
                    : { position: "relative" }
                }
              >
                {tempData?.front ? (
                  <IconButton
                    color="error"
                    sx={{ position: "absolute", top: 5, right: 5 }}
                    onClick={() =>
                      setTempData((prevData: any) => ({
                        ...prevData,
                        front: "",
                      }))
                    }
                  >
                    <Delete />
                  </IconButton>
                ) : (
                  <>
                    <input
                      accept="image/*" // You can set the accepted file types
                      style={{ display: "none" }}
                      id="file-upload"
                      type="file"
                      onChange={(e: any) => {
                        handleImageUpload(e, 1);
                        e.target.value = null;
                      }}
                    />
                    <label htmlFor="file-upload">
                      <IconButton
                        color="default"
                        component="span"
                        sx={{ position: "absolute", bottom: 20, left: "40%" }}
                        style={{ backgroundColor: "aliceblue" }}
                      >
                        <CameraAltTwoTone />
                      </IconButton>
                    </label>
                  </>
                )}
                <img
                  style={{ width: "155px", height: "155px" }}
                  src={
                    tempData?.front
                      ? tempData?.front
                      : "/static/images/vehicle_front.jpg"
                  }
                  alt="Front"
                ></img>
                <figcaption>Front</figcaption>
              </figure>

              <figure
                style={
                  tempData?.side
                    ? { position: "relative", marginTop: "20px" }
                    : { position: "relative" }
                }
              >
                {tempData?.side ? (
                  <IconButton
                    color="error"
                    sx={{ position: "absolute", top: 5, right: 5 }}
                    onClick={() =>
                      setTempData((prevData: any) => ({
                        ...prevData,
                        side: "",
                      }))
                    }
                  >
                    <Delete />
                  </IconButton>
                ) : (
                  <>
                    <input
                      accept="image/*" // You can set the accepted file types
                      style={{ display: "none" }}
                      id="file-upload2"
                      type="file"
                      onChange={(e: any) => {
                        handleImageUpload(e, 2);
                        e.target.value = null;
                      }}
                    />
                    <label htmlFor="file-upload2">
                      <IconButton
                        color="default"
                        component="span"
                        sx={{ position: "absolute", bottom: 20, left: "40%" }}
                        style={{ backgroundColor: "aliceblue" }}
                      >
                        <CameraAltTwoTone />
                      </IconButton>
                    </label>
                  </>
                )}
                <img
                  style={{ width: "155px", height: "155px" }}
                  src={
                    tempData?.side
                      ? tempData?.side
                      : "/static/images/vehicle_side.jpg"
                  }
                  alt="Front"
                ></img>
                <figcaption>Side</figcaption>
              </figure>

              {/* 
                        <figure>
                            <img src={vehicleData?.back?vehicleData?.back:} alt="Back" />
                            <figcaption>Back</figcaption>
                        </figure> */}

              <figure
                style={
                  tempData?.back
                    ? { position: "relative", marginTop: "20px" }
                    : { position: "relative" }
                }
              >
                {tempData?.back ? (
                  <IconButton
                    color="error"
                    sx={{ position: "absolute", top: 5, right: 5 }}
                    onClick={() =>
                      setTempData((prevData: any) => ({
                        ...prevData,
                        back: "",
                      }))
                    }
                  >
                    <Delete />
                  </IconButton>
                ) : (
                  <>
                    <input
                      accept="image/*" // You can set the accepted file types
                      style={{ display: "none" }}
                      id="file-upload3"
                      type="file"
                      onChange={(e: any) => {
                        handleImageUpload(e, 3);
                        e.target.value = null;
                      }}
                    />
                    <label htmlFor="file-upload3">
                      <IconButton
                        color="default"
                        component="span"
                        sx={{ position: "absolute", bottom: 20, left: "40%" }}
                        style={{ backgroundColor: "aliceblue" }}
                      >
                        <CameraAltTwoTone />
                      </IconButton>
                    </label>
                  </>
                )}
                <img
                  style={{ width: "155px", height: "155px" }}
                  src={
                    tempData?.back
                      ? tempData?.back
                      : "/static/images/vehicle_back.jpg"
                  }
                  alt="Front"
                ></img>
                <figcaption>Back</figcaption>
              </figure>
            </div>
          }
          {/* <figure className="registration_img">
                        <img src={vehicleData?.vehicleReg?vehicleData?.vehicleReg:} alt="Registration" />
                    </figure> */}
          <figure className="registration_img" style={{ position: "relative" }}>
            {tempData?.vehicleReg ? (
              <IconButton
                color="error"
                sx={{ position: "absolute", top: 10, right: 20 }}
                onClick={() =>
                  setTempData((prevData: any) => ({
                    ...prevData,
                    vehicleReg: "",
                  }))
                }
              >
                <Delete />
              </IconButton>
            ) : (
              <>
                <input
                  accept="image/*" // You can set the accepted file types
                  style={{ display: "none" }}
                  id="file-upload4"
                  type="file"
                  onChange={(e: any) => {
                    handleImageUpload(e, 4);
                    e.target.value = null;
                  }}
                />
                <label htmlFor="file-upload4">
                  <IconButton
                    color="default"
                    component="span"
                    sx={{ position: "absolute", bottom: -15, left: "45%" }}
                    style={{ backgroundColor: "aliceblue" }}
                  >
                    <CameraAltTwoTone />
                  </IconButton>
                </label>
              </>
            )}
            <img
              style={{ width: "505px", height: "236px" }}
              src={
                tempData?.vehicleReg
                  ? tempData?.vehicleReg
                  : "/static/images/vehicleRegistration_attachment.jpg"
              }
              alt="Front"
            ></img>
            {/* <figcaption>Front</figcaption> */}
          </figure>
          <div className="footer_btn_flex">
            <Button
              className="btn btn_secondary"
              onClick={() => {
                if (!tempData?.front) {
                  showError("Please upload front image");
                  return;
                }
                if (!tempData?.side) {
                  showError("Please upload side image");
                  return;
                }
                if (!tempData?.back) {
                  showError("Please upload back image");
                  return;
                }
                if (!tempData?.vehicleReg) {
                  showError("Please upload vehicle registration image");
                  return;
                }
                setVehicleData(tempData);
                setOpen(false);
                showToast("Vehicle images updated successfully");
              }}
            >
              Submit
            </Button>
            <Button
              className="btn btn_highlight"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
