import { END_POINTS } from "../helpers/constants/urls";
import { CommonBody } from "../types/General";
// import { DashboardData } from "../types/General";

import emptySplitApi from "../utils/rtk";
interface updateReport {
  privacyPolicy: string;
  email: string;
  phone: string;
  countryCode: string;
  termsAndCondition: string;
  about_us: string;
  messengerId: string;
}
type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const dashboardApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReport: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.adminReport}`,
        method: "GET",
      }),
    }),
    getTravellerDetailChart: builder.query<
      CommonResponseType & { data: any },
      {}
    >({
      query: () => ({
        url: `${END_POINTS.travellerDetailChart}`,
        method: "GET",
      }),
    }),
    getCountryWiseReport: builder.query<CommonResponseType & { data: any }, {}>(
      {
        query: () => ({
          url: `${END_POINTS.countryWiseReport}`,
          method: "GET",
        }),
      }
    ),
    getWeightChart: builder.query<
      CommonResponseType & { data: any },
      { transportType: number }
    >({
      query: ({ transportType }) => ({
        url: `${END_POINTS.weightChart}?transportType=${transportType}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useLazyGetReportQuery,
  useLazyGetTravellerDetailChartQuery,
  useLazyGetWeightChartQuery,
  useGetCountryWiseReportQuery,
} = dashboardApi;
