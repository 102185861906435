import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

export default function BarChartGraph({ dataArray }: any) {
  return (
    <BarChart
      xAxis={[
        {
          tickPlacement: "middle",
          scaleType: "band",
          data: ["Envelope", "Carton", "Suitcase", "Box", "Barrel", "Other"],
        },
      ]}
      series={[
        {
          data: [
            dataArray?.find((data: any) => data?.cargoType == "ENVELOPE")
              ?.totalWeight,
            dataArray?.find((data: any) => data?.cargoType == "CARTON")
              ?.totalWeight,
            dataArray?.find((data: any) => data?.cargoType == "SUITCASE")
              ?.totalWeight,
            dataArray?.find((data: any) => data?.cargoType == "BOX")
              ?.totalWeight,
            dataArray?.find((data: any) => data?.cargoType == "BARREL")
              ?.totalWeight,
            dataArray?.find((data: any) => data?.cargoType == "OTHER")
              ?.totalWeight,
          ],
        },
      ]}
      width={500}
      height={300}
      colors={["#4E148C"]}
      borderRadius={50}
    />
  );
}
