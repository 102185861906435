import { Modal, Button, Tooltip } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import useAuth from "../hooks/useAuth";
import { Delete } from "@mui/icons-material";
import { showError } from "../constants/toasts";

interface AddCommentModalProps {
  open: boolean;
  onClose: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
  commentData: any;
  setCommentData: any;
  editByIdData: any;
}

export default function AddComment({
  open,
  onClose,
  setOpen,
  commentData,
  setCommentData,
  editByIdData,
}: AddCommentModalProps) {
  const [comment, setComment] = useState({ title: "", message: "" });
  const user = useAuth();

  const handleAddComment = () => {
    if (comment?.message.trim() && comment?.title.trim()) {
      setCommentData((prev: any) => [
        ...prev,
        {
          _id: user?._id,
          fullName: user?.fullName,
          title: comment.title,
          message: comment.message,
          image: user?.image,
        },
      ]);
      setComment({ message: "", title: "" });
    } else {
      showError("Please add all fields");
    }
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setComment((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  return (
    <Modal
      className="modal addComment_modal"
      id="addCommentModal"
      aria-labelledby="addComment-modal-title"
      aria-describedby="addComment-modal-description"
      open={open}
      onClose={() => {
        setComment({ message: "", title: "" });
        onClose();
      }}
    >
      <div className="modal-dialog">
        <div className="modal-body">
          <div className="btn-close" onClick={() => setOpen(false)}>
            <CloseIcon />
          </div>
          <div className="modal_title">
            <h2>Add Comments</h2>
            <p>Comment given by admins</p>
          </div>
          <form className="form" action="">
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Title"
                name="title"
                onChange={handleChange}
                fullWidth
                hiddenLabel
                value={comment?.title}
              />
            </div>
            <div className="control_group">
              <TextField
                variant="outlined"
                placeholder="Description"
                fullWidth
                hiddenLabel
                name="message"
                multiline
                maxRows={5}
                minRows={5}
                onChange={handleChange}
                value={comment?.message}
              />
            </div>
            <div className="footer_btn_flex">
              <Button className="btn btn_primary" onClick={handleAddComment}>
                Add Comment
              </Button>
            </div>
          </form>

          <ul className="fcComment_list v2">
            {commentData?.map((data: any, index2: number) => (
              <li>
                <Tooltip title={data?.fullName}>
                  <figure>
                    <img
                      src={data?.image || "/static/images/user_attachment.jpg"}
                      alt="User"
                    />
                  </figure>
                </Tooltip>
                <p>
                  <b>{data?.title || "-"}</b>
                  <span>{data?.message || "-"}</span>
                </p>
                {data?._id == user?._id && (
                  <p
                    style={{
                      position: "absolute",
                      right: 30,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setCommentData((prev: any) =>
                        prev.filter((data: any, index: any) => index != index2)
                      )
                    }
                  >
                    <Delete fontSize="small" htmlColor="red" />
                  </p>
                )}
              </li>
            ))}
          </ul>
          <div className="footer_btn_flex">
            <Button
              className="btn btn_highlight"
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button className="btn btn_primary" onClick={editByIdData}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
