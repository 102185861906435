import { useEffect, useState } from "react";
import BarChartGraph from "../../components/Barchart";
import { showError } from "../../constants/toasts";
import { useLazyGetWeightChartQuery } from "../../services/reports";

const WeightsBreakdown = () => {
  const [isActive, setIsActive] = useState(1);
  const [getData] = useLazyGetWeightChartQuery();
  const getDataList = async () => {
    try {
      const response = await getData({ transportType: isActive }).unwrap();
      if (response?.statusCode == 200) {
        setData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "Something went wrong");
    }
  };
  const [data, setData] = useState();
  useEffect(() => {
    getDataList();
  }, [isActive]);
  return (
    <>
      <div className="cards weightsBreakdown_card">
        <h2>Weights Breakdown</h2>

        <ul>
          <li
            className={isActive == 1 ? "active" : ""}
            onClick={() => setIsActive(1)}
          >
            <img src="/static/images/airplane_icon.svg" alt="icon" />
            <p>Airline</p>
          </li>
          <li
            className={isActive == 2 ? "active" : ""}
            onClick={() => setIsActive(2)}
          >
            <img src="/static/images/bus_icon.svg" alt="icon" />
            <p>Train</p>
          </li>
          <li
            className={isActive == 3 ? "active" : ""}
            onClick={() => setIsActive(3)}
          >
            <img src="/static/images/ship2_icon.svg" alt="icon" />
            <p>Sea</p>
          </li>
          <li
            className={isActive == 4 ? "active" : ""}
            onClick={() => setIsActive(4)}
          >
            <img src="/static/images/people_icon.svg" alt="icon" />
            <p>Private</p>
          </li>
          <li
            className={isActive == 5 ? "active" : ""}
            onClick={() => setIsActive(5)}
          >
            <img src="/static/images/profile2_icon.svg" alt="icon" />
            <p>Public</p>
          </li>
        </ul>

        <figure style={{ width: "900px", marginLeft: "45px" }}>
          {/* <img src="/static/images/breakdown_graph.png" alt="" /> */}
          <BarChartGraph dataArray={data} />
        </figure>
      </div>
    </>
  );
};

export default WeightsBreakdown;
