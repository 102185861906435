import React from "react";
import { Button } from "@mui/material";

const SelectDuration = ({ data }: any) => {
  const returnPercentData = (currentCount: number, total: number) => {
    if (!total) return;
    return ((currentCount / total) * 100).toFixed(1);
  };
  return (
    <>
      <div className="cards s_duration">
        {/* <div className="cards_header">
          <div className="left_s">
            <h2>
              <img src="/static/images/calender_icon.svg" alt="Icon" /> Select
              Duration
            </h2>
          </div>
          <div className="right_s">
            <p>
              <span>Month</span>
              <span>Week</span>
              <span>Today</span>
            </p>
            <Button className="btn btn_highlight">
              <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
            </Button>
          </div>
        </div> */}
        <ul className="duration_list gap_p">
          <li>
            <figure>
              <img src="/static/images/duration_icon5.svg" alt="" />
            </figure>
            <p>
              <b>
                {data?.travelTypeCounts?.travelTypeCounts?.AIRLINES ||
                  // find(
                  //   (data2: any) => data2?.travelType == "AIRLINES"
                  // )?.count
                  0}
              </b>
              <span>Travel through airline</span>
            </p>
          </li>
          <li>
            <figure>
              <img src="/static/images/duration_icon4.svg" alt="" />
            </figure>
            <p>
              <b>
                {data?.travelTypeCounts?.travelTypeCounts?.RAILWAY ||
                  // find(
                  //   (data2: any) => data2?.travelType == "RAILWAY"
                  // )?.count
                  0}
              </b>
              <span>Travel through train</span>
            </p>
          </li>
          <li>
            <figure>
              <img src="/static/images/duration_icon3.svg" alt="" />
            </figure>
            <p>
              <b>
                {data?.travelTypeCounts?.travelTypeCounts?.VOYAGE ||
                  // find(
                  //   (data2: any) => data2?.travelType == "VOYAGE"
                  // )?.count
                  0}
              </b>
              <span>Travel through ship</span>
            </p>
          </li>
          <li>
            <figure>
              <img src="/static/images/duration_icon2.svg" alt="" />
            </figure>
            <p>
              <b>
                {data?.travelTypeCounts?.travelTypeCounts?.PRIVATE_VEHICLE ||
                  // find(
                  //   (data2: any) => data2?.travelType == "PRIVATE_VEHICLE"
                  // )?.count
                  0}
              </b>
              <span>Travel through car</span>
            </p>
          </li>
          <li>
            <figure>
              <img src="/static/images/duration_icon1.svg" alt="" />
            </figure>
            <p>
              <b>
                {" "}
                {data?.travelTypeCounts?.travelTypeCounts?.PUBLIC_ROAD ||
                  // find(
                  //   (data2: any) => data2?.travelType == "PUBLIC_ROAD"
                  // )?.count
                  0}
              </b>
              <span>Travel through bus</span>
            </p>
          </li>
          <li>
            <figure>
              <img src="/static/images/duration_icon1.svg" alt="" />
            </figure>
            <p>
              <b>
                F{" "}
                {returnPercentData(
                  data?.travelGenderData?.genderBreakdown?.find(
                    (data2: any) => data2?.gender == "2"
                  )?.total || 0,
                  data?.travelGenderData?.overallTotal
                ) || 0}
                % - M{" "}
                {returnPercentData(
                  data?.travelGenderData?.genderBreakdown?.find(
                    (data2: any) => data2?.gender == "1"
                  )?.total || 0,
                  data?.travelGenderData?.overallTotal
                ) || 0}
                %
              </b>
              <span>Travelers Gender</span>
            </p>
          </li>
          <li>
            <figure>
              <img src="/static/images/duration_icon1.svg" alt="" />
            </figure>
            <p>
              <b>
                F{" "}
                {returnPercentData(
                  data?.shipmentGenderData?.genderBreakdown?.find(
                    (data2: any) => data2?.gender == "2"
                  )?.total || 0,
                  data?.shipmentGenderData?.overallTotal
                ) || 0}
                % - M{" "}
                {returnPercentData(
                  data?.shipmentGenderData?.genderBreakdown?.find(
                    (data2: any) => data2?.gender == "1"
                  )?.total || 0,
                  data?.shipmentGenderData?.overallTotal
                ) || 0}
                %
              </b>
              <span>Shipping Customer</span>
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SelectDuration;
