import { Box, Modal } from "@mui/material";
import "./loader.css";
const Loader = ({ isLoad }: { isLoad: boolean }) => {
  const style = {
    outline: "none",
  };
  return (
    <Box>
      {isLoad ? (
        <Modal open>
          <Box className="loader_loading" sx={style}>
            <figure>
              <img src="/static/images/loader.gif" alt="loading" />
            </figure>
            {/* <div className="spinner"></div> */}
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </Box>
  );
};

export default Loader;
