import React from "react";
import MainContainer from "../../layout/MainContainer";
import TableComponent from "../../components/TableComponent";
import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const TravelerList = () => {
  const navigate = useNavigate();

  const rows = [
    {
      id: 1,
      membershipId: "GN12xxxxxxx",
      date: "12 Jan 2024",
      gender: "Female",
      mobile: "(555) 123-4567",
      country: "Saudi Arab",
      orderSubmited: "Yes",
      socialMedia: "Social",
    },
    {
      id: 2,
      membershipId: "GN12xxxxxxx",
      date: "12 Jan 2024",
      gender: "Female",
      mobile: "(555) 123-4567",
      country: "Saudi Arab",
      orderSubmited: "Yes",
      socialMedia: "Social",
    },
  ];

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 135,
      field: "membershipId",
      headerName: "Membership #",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 190,
      field: "name",
      headerName: "Name",
      editable: false,
      sortable: false,
      renderCell: () => {
        return (
          <div className="user_block">
            <figure onClick={() => navigate("/profile-detail")}>
              <img src="/static/images/user_attachment.jpg" alt="" />
            </figure>
            <p>
              <b>Emma Somith</b> GN12xxxxxxx
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "date",
      headerName: "Date",
      editable: false,
    },
    {
      flex: 1,
      field: "gender",
      headerName: "Gender",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
    },
    {
      flex: 1,
      field: "country",
      headerName: "Country",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "orderSubmited",
      headerName: "Order submited",
      editable: false,
      renderCell: () => {
        return <p className="c_primary">Yes</p>;
      },
    },
    {
      flex: 1,
      minWidth: 150,
      field: "socialMedia",
      headerName: "Social Media",
      editable: false,
      sortable: false,
      renderCell: () => {
        return (
          <div className="table_actions">
            <Box>
              <IconButton className="round_btn">
                <img src="/static/images/facebook_icon.svg" alt="Icon" />
              </IconButton>
            </Box>
            <Box>
              <IconButton className="round_btn">
                <img src="/static/images/instagram_icon.svg" alt="Icon" />
              </IconButton>
            </Box>
            <Box>
              <IconButton className="round_btn">
                <img src="/static/images/twitter_icon.svg" alt="Icon" />
              </IconButton>
            </Box>
          </div>
        );
      },
    },
  ];

  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columnVisibilityModel={[]}
          columns={columns?.filter((item) => !hidden.includes(item.field))}
          rows={rows}
        />
      </div>
    </>
  );
};

export default TravelerList;
