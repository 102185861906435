import { useNavigate } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import AcceptedOrdersList from "../../../features/shipmentTracks/newOrders/acceptedOrdersList";
import DeliverTravelersFilter from "../../../features/shipmentTracks/deliverToTravelers/deliverTravelersFilter";
import DeliverTravelersList from "../../../features/shipmentTracks/deliverToTravelers/deliverTravelersList";
import { useGridApiRef } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import { showError } from "../../../constants/toasts";
import { useLazyGetNewOrderQuery } from "../../../services/shipmentTrack";
import { NewOrder } from "../../../types/newOrder";
import Loader from "../../../constants/Loader";

const DeliverToTravelers = () => {
  const navigate = useNavigate();
  const gridApi = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });

  const [valueTabs, setValueTabs] = React.useState(0);
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [getAllData] = useLazyGetNewOrderQuery();
  const [newOrders, setNewOrders] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
  });
  const getAllDataList = async () => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: 3,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setNewOrders(response?.data?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");

  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<any>({
    freightCustomer: true,
    travelers: true,
    // contact: true,
    // email: true,
    currentCity: true,
    destinationCity: true,
    shipmentNo: true,
    offers: true,
    viewDetail: true,
    status: true,
    action: true,
    dateOfOrder: true,
    departureDate: true,
    delayDepartureDate: true,
    arrivedDate: true,
    expectingDeliveryDate: true,
    viewRemark: true,
    dateOfAcceptance: true,
  });
  useEffect(() => {
    getAllDataList();
  }, []);
  const getFilteredData = (data: any) => {
    console.log("searchTerm :", searchTerm);
    if (!selectedStatus)
      return data.filter(
        (data: NewOrder) =>
          ("SH" + data?.shipmentId?.shipmentId)
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.email
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.email
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.phone
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.phone
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.firstName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.lastName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.firstName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.lastName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString())
      );

    return data.filter(
      (data: NewOrder) =>
        (("SH" + data?.shipmentId?.shipmentId)
          ?.toLocaleLowerCase?.()
          ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.email
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.email
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.phone
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.phone
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.firstName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.customer?.lastName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.firstName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString()) ||
          data?.traveller?.lastName
            ?.toLocaleLowerCase?.()
            ?.includes(searchTerm?.toLocaleLowerCase?.().toString())) &&
        data?.trackingStatus == selectedStatus
    );
  };
  console.log("karan negi", getFilteredData(newOrders));

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Delivery to / from passenger</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Shipment Track - Delivery to / from passenger
        </p>
      </div>

      <DeliverTravelersFilter
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
        selectedRow={selectedRow}
        gridApi={gridApi}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        columnVisibilityModel={columnVisibilityModel1}
        setColumnVisibilityModel={setColumnVisibilityModel1}
      />

      <div className="cards">
        <DeliverTravelersList
          getDataList={getAllDataList}
          setSelectedRow={setSelectedRow}
          gridApi={gridApi}
          newOrders={getFilteredData(newOrders)}
          columnVisibilityModel={columnVisibilityModel1}
        />
      </div>
    </>
  );
};

export default DeliverToTravelers;
