/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { showToast, showError } from "../../constants/toasts";
import { useEditTravelerByIdMutation } from "../../services/travelers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import { Travel, TravelDetail } from "../../types/traveller";
import { InfoTwoTone } from "@mui/icons-material";

const ApprovedTravelersList = ({
  travelers,
  columnVisibilityModel,
  gridApi,
  setSelectedRow,
  getDataList,
}: any) => {
  const navigate = useNavigate();

  const getTravelTypeName = (travelType: number) => {
    if (travelType == 1) {
      return "Private Vehicle";
    } else if (travelType == 2) {
      return "Airlines";
    } else if (travelType == 3) {
      return "Voyage";
    } else if (travelType == 4) {
      return "Railway";
    } else if (travelType == 5) {
      return "Public Road";
    } else {
      return "";
    }
  };

  const [updateData] = useEditTravelerByIdMutation();

  const handleStatusChange = async (id: any, isBlocked: boolean) => {
    try {
      const body = {
        isBlocked: isBlocked,
      };

      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Traveller status updated successfully");
      getDataList();
    } catch (error: any) {
      showError(error?.data?.message || "");
      //
    }
  };
  const rows: any = [];
  travelers?.map((data: Travel) => {
    rows.push({
      id: data?._id,
      name: data?.userDetails?.[0]?.fullName
        ? data?.userDetails?.[0]?.fullName
        : "-",
      TR: "TR" + data?.travelId,
      availableWeight: data?.travelDetail?.[0]?.availableWeight?.toString(),
      departureCityDate:
        data?.currentAddress?.city +
        " /" +
        data?.travelDetail?.[0]?.departureDate?.slice(0, 10),
      arrivalCityDate:
        data?.destinationAddress?.[data?.destinationAddress?.length - 1]?.city +
        "/" +
        data?.travelDetail?.[
          data?.travelDetail?.length - 1
        ]?.arrivalDate?.slice(0, 10),
      mobile: data?.userDetails?.[0]?.countryCode
        ? data?.userDetails?.[0]?.countryCode + data?.userDetails?.[0]?.phone
        : "-",
      phone: data?.userDetails?.[0]?.phone,
      countryCode: data?.userDetails?.[0]?.countryCode,
      email: data?.userDetails?.[0]?.email
        ? data?.userDetails?.[0]?.email
        : "-",
      approveDate: data?.updatedAt?.slice(0, 10),
      currentCity: data?.currentAddress?.city
        ? data?.currentAddress?.city
        : "-",
      wayOfTravel:
        data?.travelDetail?.length !== 1
          ? `${data?.travelDetail?.length} Travels`
          : getTravelTypeName(data?.travelDetail?.[0]?.travelType),
      image: data?.userDetails?.[0]?.personVerification?.profileImage,
      isBlocked: data?.isBlocked,
      userId: data?.userDetails?.[0]?._id,
      travelDetail: data?.travelDetail,
      status: data?.isBlocked ? "Disabled" : "Enabled",
    });
  });
  const ActionCell = ({ data }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };

    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {data?.row?.isBlocked ? (
              <MenuItem
                onClick={() => handleStatusChange(data?.row?.id, false)}
              >
                Enable
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleStatusChange(data?.row?.id, true)}>
                Disable
              </MenuItem>
            )}
          </Menu>
        </Box>
      </div>
    );
  };

  const user = useAuth();
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 140,
      field: "TR",
      headerName: "TR",
      align: "center",
      headerAlign: "center",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 200,
      field: "name",
      headerName: "Name",
      editable: false,
      headerAlign: "center",
      renderCell: (data) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.userId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <Tooltip
              title={
                <p style={{ color: "white" }}>
                  {data?.row?.name}
                  <br />
                  {data?.row?.mobile}
                  <br />
                  {data?.row?.email}
                </p>
              }
            >
              <p className="cursor_p">
                <b>{data?.row?.name}</b>
                {data?.row?.mobile}
                <br />
                {data?.row?.email}
              </p>
            </Tooltip>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 180,
      field: "availableWeight",
      headerName: "Available Weight",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 200,
      field: "departureCityDate",
      headerName: "Departure City/Date",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "arrivalCityDate",
      headerName: "Arrival City/Date",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "approveDate",
      headerName: "Date of approved",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "currentCity",
      headerName: "Current city",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "email",
      headerName: "Email Address",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "status",
      headerName: "Status",
      editable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      flex: 1,
      minWidth: 180,
      field: "wayOfTravel",
      headerName: "Way Of Travel",
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => {
        const luggageArray: any = [];
        data?.row?.travelDetail?.map((data2: TravelDetail) => {
          if (data2?.travelType) luggageArray?.push(data2?.travelType);
        });

        const displayData = (
          <ul>
            {data?.row?.travelDetail?.map(
              (data2: TravelDetail, index: number) => {
                return (
                  <li>
                    {index +
                      1 +
                      ". " +
                      (getTravelTypeName(data2?.travelType)?.trim().length
                        ? getTravelTypeName(data2?.travelType)
                        : "-")}
                  </li>
                );
              }
            )}
          </ul>
        );
        return (
          <>
            {luggageArray.length ? (
              luggageArray?.length == 1 ? (
                <p>{getTravelTypeName(luggageArray[0])}</p>
              ) : (
                <p style={{ display: "flex", alignItems: "center" }}>
                  {luggageArray?.length + " Travels  "}
                  <Tooltip title={displayData} arrow>
                    <IconButton>
                      <InfoTwoTone />
                    </IconButton>
                  </Tooltip>
                </p>
              )
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      flex: 1,
      minWidth: 80,
      disableExport: true,
      field: "action",
      headerName: "Action",
      editable: false,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (data) => <ActionCell data={data} />,
    },
  ];
  // useEffect(()=>{
  //     if(rows)
  //     setSelectedRow((prevData:any)=>{
  //       return {...prevData,row:rows}
  //      })
  //   },[columns])
  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columnVisibilityModel={columnVisibilityModel}
          columns={columns?.filter((item) => !hidden.includes(item.field))}
          rows={rows}
        />
      </div>
    </>
  );
};
export default ApprovedTravelersList;
