import React, { useEffect, useState } from "react";
import AuthLayout from "../../layout/authLayout";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useFormik } from "formik";
import { showToast, showError } from "../../constants/toasts";
import { useForgotPasswordMutation } from "../../services/auth";
import { CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import * as Yup from "yup";
import { errorToast } from "../../helpers";
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [linkSent, setLinkSent] = useState(false);
  // const [ForgotPasswordMutation, { isLoading }] =
  //   usePostForgotPasswordMutation();
  const [isLinkSentAlready, setIsLinkSentAlready] = useState(false);
  const [countDown, setCountDown] = useState<number>(0);
  const [ForgotPasswordMutation, { isLoading }] = useForgotPasswordMutation();

  const handleResendEmail = async (e: any) => {
    e.preventDefault();
    let body = {
      key: formik?.values?.key,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await ForgotPasswordMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        // successToast("OTP Resent Successfully");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  const formik = useFormik({
    initialValues: {
      key: "",
    },
    validationSchema: Yup.object({
      key: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),

    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.key,
        // appKey: new Date().toISOString(),
      };

      try {
        const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await ForgotPasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            key: formik.values.key,
          };
          // navigate("/verifyotp", { state: data });
          setLinkSent(true);
          // setIsLinkSentAlready(true);
          setCountDown(59);

          showToast(response?.message);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <div className="authBox">
        <h2>Forgot Password</h2>
        <p>Enter your email to reset your password.</p>
        <form onSubmit={formik.handleSubmit}>
          <Input
            placeholder="Email"
            value={formik.values.key}
            name="key"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            helperText={formik.touched.key && formik.errors.key}
          />
          {linkSent && (
            <div className="forgotMsg">
              <figure>
                <img src="/static/images/msgIcon.png" alt="" />
              </figure>
              <div className="sucdiv">
                <h3>Success!</h3>
                <h4>We send a password reset link to your email</h4>
              </div>
            </div>
          )}

          <Button
            value={
              linkSent
                ? countDown == 0
                  ? "Resend Reset Link"
                  : "Resend in 00:" + (countDown < 10 ? "0" : "") + countDown
                : "Send Reset Link"
            }
            disabled={Boolean(countDown)}
          />
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
