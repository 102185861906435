import React from "react";
import "./Auth.scss";
import { Outlet } from "react-router-dom";

const AuthLayout = ({ children }: any) => {
  return (
    <div className="authContainer">
      <div className="container-auth">
        <div className="authpage">
          <div className="authLeft">
            <figure>
              <img src="/static/images/authLogo.png" alt="" />
              <p
                style={{
                  fontSize: "21.5px",
                  margin: "7px",
                  textAlign: "center",
                }}
              >
                We are on a mission at Ginaex to change the way people Think
                about Travel and Shipping
              </p>
            </figure>
          </div>
          <div className="authRight">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
