import React, { SetStateAction, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { showError } from "../../constants/toasts";
import { useLazyGetFreightCustomerQuery } from "../../services/freightCustomer";
import { getLuggageName } from "../../constants/cargoType";
interface CustomerDetails {
  _id: string;
  vehicleDetail?: VehicleDetail;
  currentAddress: CurrentAddress;
  personVerification: PersonVerification;
  shipmentDetail: ShipmentDetail;
  multiCity: boolean;
  ticket?: any[];
  adminStatus: number;
  shipmentStatus: number;
  rejectionReason: any;
  cargoType: any[];
  drugsVolunteer: boolean;
  shipmentId: string;
  status: boolean;
  additionalShipment: boolean;
  isCancelled: boolean;
  isBlocked: boolean;
  isDeleted: boolean;
  saveForLater: boolean;
  travelReminderSent: boolean;
  flightOnTimeStatus?: number;
  adminTimeStatus?: number;
  comment?: any[];
  flag?: boolean;
  packageDetail: PackageDetail[];
  userId: string;
  type: number;
  travelDetail: any[];
  destinationAddress: any[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  cancelReason?: string;
  userDetails: UserDetail[];
  beneficiaryDetail: BeneficiaryDetail;
  closedBy?: string;
  refundIssueStatus?: number;
  refundRemark?: string;
}

interface VehicleDetail {
  vehicleReg: any;
  front: any;
  side: any;
  back: any;
}

interface CurrentAddress {
  city?: string;
  district?: string;
  square: any;
  houseNumber: any;
  countryCode: any;
  phone: any;
  whatsappNumber: any;
  address: string;
}

interface PersonVerification {
  profileImage: string;
  idCard?: string;
  residenceCard: string;
  residenceType: number;
}

interface ShipmentDetail {
  quantity: number;
  totalWeight: any;
  photo: string;
  front: string;
  side: string;
  back: string;
  packagingServiceNeeded: boolean;
}

interface PackageDetail {
  quantity: number;
  totalWeight: number;
  photo: string;
  front: string;
  side: string;
  back: string;
  packagingServiceNeeded: boolean;
  parcelCategory: number[];
  parcelDescription: string;
  _id: string;
  cargoType: number;
}

interface UserDetail {
  _id: string;
  personVerification: PersonVerification2;
  fullName: string;
  phone: string;
  image: string;
  countryCode: string;
  email: string;
}

interface PersonVerification2 {
  profileImage: string;
  idCard?: string;
  residenceCard: string;
  residenceType: number;
}

interface BeneficiaryDetail {
  _id: string;
  customerFreightId: string;
  __v: number;
  address: string;
  city: string;
  country: string;
  countryCode: string;
  createdAt: string;
  customerCurrentCity: string;
  customerCurrentDistrict: string;
  deliverToHome: boolean;
  membershipId: string;
  name: string;
  phone: string;
  updatedAt: string;
  image?: string;
  userId?: string;
}

const OrderList = ({
  setIsLoading,
}: {
  setIsLoading: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const { id } = useParams();
  const [getAllCustomer] = useLazyGetFreightCustomerQuery();
  const [customerData, setCustomerData] = useState<any>([]);
  const [customerType, setCustomerType] = useState<number[][]>([]);

  const getCustomerData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllCustomer({
        page: 1,
        query: id,
        type: undefined,
      }).unwrap();
      if (response.statusCode == 200) {
        setCustomerData(response?.data);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (id) getCustomerData();
  }, []);
  const [expanded, setExpanded] = React.useState<boolean | number | string>(
    false
  );

  const handleChange =
    (panel: number | string) =>
    (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <div className="custom_accordion">
        {customerData?.length ? (
          customerData?.map((data: CustomerDetails | any, index: number) => {
            return (
              <Accordion
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  Shipping NO {"SH" + data?.shipmentId}
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table className="v2" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>From</TableCell>
                          <TableCell>To</TableCell>
                          <TableCell>Luggage type</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Total Weight (Kgs)</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.packageDetail?.map(
                          (data2: PackageDetail, index2: number) => {
                            return (
                              <TableRow>
                                <TableCell>{index2 + 1}</TableCell>
                                <TableCell>
                                  {data?.beneficiaryDetail?.customerCurrentCity}
                                </TableCell>
                                <TableCell>
                                  {data?.beneficiaryDetail?.city}
                                </TableCell>
                                <TableCell>
                                  {getLuggageName(data2?.cargoType)}
                                </TableCell>
                                <TableCell>{data2?.quantity}</TableCell>
                                <TableCell>{data2?.totalWeight}</TableCell>
                                <TableCell>
                                  {data?.orders?.[0]?.packageDetails?.[index2]
                                    ?.amount
                                    ? data?.orders?.[0]?.packageDetails?.[
                                        index2
                                      ]?.amount
                                    : "-"}
                                </TableCell>
                                <TableCell>
                                  {data?.adminStatus == 1 && (
                                    <Button className="btn_text c_info">
                                      New
                                    </Button>
                                  )}
                                  {data?.adminStatus == 2 && (
                                    <Button className="btn_text c_success">
                                      Approved
                                    </Button>
                                  )}
                                  {data?.adminStatus == 3 && (
                                    <Button className="btn_text c_danger">
                                      Rejected
                                    </Button>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <h1 style={{ textAlign: "center", padding: "80px" }}>No Shipments</h1>
        )}
      </div>
    </>
  );
};

export default OrderList;
