import React from "react";
import { PropsWithoutRef } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SIDEBAR_WIDTH } from "../constants";
import useAuth from "../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../constants/role";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();
  const user=useAuth();
  const [open, setOpen] = React.useState(true);
  const [open1, setOpen1] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [open3, setOpen3] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClick1 = () => {
    setOpen1(!open1);
  };
  const handleClick2 = () => {
    setOpen2(!open2);
  };
  const handleClick3 = () => {
    setOpen3(!open3);
  };

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/ginaex-logo.svg"
            alt=""
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <img src="/static/images/dashboard_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[0]?.isView)) ? <ListItemButton
          className="lst-itm" onClick={handleClick}
          selected={location.pathname === "/registrations/travelers" ||
            location.pathname === "/registrations/freight-customers" ||
            location.pathname === "/registrations/beneficiary-customers"}
        >
          <ListItemIcon>
            <img src="/static/images/note_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Registrations" />
          {open ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
        </ListItemButton>    :null}
        <Collapse
          in={!open || location.pathname === "/registrations/travelers" ||
            location.pathname === "/registrations/freight-customers" ||
            location.pathname === "/registrations/beneficiary-customers"}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/registrations/travelers"}
              onClick={() => navigate("/registrations/travelers")}
            >
              <ListItemText primary="Travelers" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/registrations/freight-customers"}
              onClick={() => navigate("/registrations/freight-customers")}
            >
              <ListItemText primary="Freight Customers" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/registrations/beneficiary-customers"}
              onClick={() => navigate("/registrations/beneficiary-customers")}
            >
              <ListItemText primary="Beneficiary" />
            </ListItemButton>
          </List>
        </Collapse>

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[1]?.isView)) ?   <ListItemButton
          className="lst-itm" onClick={handleClick1}
          selected={location.pathname === "/shipment-track/new-orders" ||
            location.pathname === "/shipment-track/deliver-to-travelers" ||
            location.pathname === "/shipment-track/passenger-flight" ||
            location.pathname === "/shipment-track/feedback"}>
          <ListItemIcon>
            <img src="/static/images/ship_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Shipment Tracks" />
          {open1 ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
        </ListItemButton> :null}
        <Collapse
          in={!open1 || location.pathname === "/shipment-track/new-orders" ||
            location.pathname === "/shipment-track/deliver-to-travelers" ||
            location.pathname === "/shipment-track/passenger-flight" ||
            location.pathname === "/shipment-track/feedback"}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/shipment-track/new-orders"}
              onClick={() => navigate("/shipment-track/new-orders")}
            >
              <ListItemText primary="New Orders" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/shipment-track/deliver-to-travelers"}
              onClick={() => navigate("/shipment-track/deliver-to-travelers")}
            >
              <ListItemText primary="Deliver to Travelers" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/shipment-track/passenger-flight"}
              onClick={() => navigate("/shipment-track/passenger-flight")}
            >
              <ListItemText primary="Passenger Flight" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/shipment-track/feedback"}
              onClick={() => navigate("/shipment-track/feedback")}
            >
              <ListItemText primary="Feedback" />
            </ListItemButton>
          </List>
        </Collapse>

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[2]?.isView)) ?  <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/report-an-issue"}
          onClick={() => navigate("/report-an-issue")}
        >
          <ListItemIcon>
            <img src="/static/images/status_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Report an Issues" />
        </ListItemButton> :null}

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[3]?.isView)) ? <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/looking-for-travellers"}
          onClick={() => navigate("/looking-for-travellers")}
        >
          <ListItemIcon>
            <img src="/static/images/driving_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Looking for Travelers "
          />
        </ListItemButton> :null}

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[4]?.isView)) ?<ListItemButton
          className="lst-itm"
          selected={location.pathname === "/reports"}
          onClick={() => navigate("/reports")}
        >
          <ListItemIcon>
            <img src="/static/images/status_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Reports" />
        </ListItemButton> :null}

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[5]?.isView)) ?<ListItemButton className="lst-itm" onClick={handleClick2}
          selected={location.pathname === "/membership-level/bronze" || location.pathname === "/membership-level/silver" || location.pathname === "/membership-level/gold"}>
          <ListItemIcon>
            <img src="/static/images/medal_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Membership Level" />
          {open2 ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
        </ListItemButton> :null}
        <Collapse
          in={!open2 || location.pathname === "/membership-level/bronze" || location.pathname === "/membership-level/silver" || location.pathname === "/membership-level/gold"}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={location.pathname === "/membership-level/bronze"}
              onClick={() => navigate("/membership-level/bronze")}
            >
              <ListItemText primary="Bronze" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/membership-level/silver"}
              onClick={() => navigate("/membership-level/silver")}
            >
              <ListItemText primary="Silver" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/membership-level/gold"}
              onClick={() => navigate("/membership-level/gold")}
            >
              <ListItemText primary="Gold" />
            </ListItemButton>
          </List>
        </Collapse>

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[6]?.isView)) ? <ListItemButton className="lst-itm" onClick={handleClick3}
          selected={location?.pathname?.includes("/user-management/roles") || location.pathname === "/user-management/user-list"}>
          <ListItemIcon>
            <img src="/static/images/medal_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="User Management" />
          {open3 ? <KeyboardArrowRightIcon /> : <KeyboardArrowDownIcon />}
        </ListItemButton> :null}
        <Collapse
          in={!open3 || location?.pathname?.includes("/user-management/roles") || location.pathname === "/user-management/user-list"}
          timeout="auto"
          unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={location?.pathname?.includes("/user-management/roles")}
              onClick={() => navigate("/user-management/roles")}>
              <ListItemText primary="Roles" />
            </ListItemButton>
            <ListItemButton
              selected={location.pathname === "/user-management/user-list"}
              onClick={() => navigate("/user-management/user-list")}>
              <ListItemText primary="User List" />
            </ListItemButton>
          </List>
        </Collapse>

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[7]?.isView)) ?<ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-notifications"}
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon>
            <img src="/static/images/notification2_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Notifications" />
        </ListItemButton> :null}

        {(user?.role==ADMIN_ROLE_ID || (user?.subadminRoleId?.permissions?.[8]?.isView)) ?<ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-cms"}
          onClick={() => navigate("/manage-cms")}
        >
          <ListItemIcon>
            <img src="/static/images/cms_icon.svg" alt="Icon" />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage CMS" />
        </ListItemButton> :null}
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
