//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/faq";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllFaqResponse = {
  data: any;
  // faq: any[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  // wpagination?:boolean;
  size?: number;
  page?: number;
  query?: string;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getFaq: builder.query<
      CommonResponseType & { data: GetAllFaqResponse },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, size, query }) => {
        let url = END_POINTS.faq;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        if (query) {
          queryParams.push(`search=${query}`);
        }
        if (size) {
          queryParams.push(`size=${size}`);
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),

    getFaqById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.faq}/${id}`,
        method: "GET",
      }),
    }),

    changeFaqStatus: builder.query<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.changeUserStatus}/${id}`,
        method: "GET",
      }),
    }),

    editFaqById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.faq}/${id}`,
        method: "PUT",
        body,
      }),
    }),

    deleteFaqById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.faq}/${id}`,
        method: "DELETE",
      }),
    }),

    addFaq: builder.mutation<CommonResponseType & { data: any }, CommonBody>({
      query: (body) => ({
        url: END_POINTS.faq,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const {
  useLazyGetFaqQuery,
  useLazyGetFaqByIdQuery,
  useEditFaqByIdMutation,
  useAddFaqMutation,
  useDeleteFaqByIdMutation,
} = userApi;
