//import { url } from "inspector";
import { END_POINTS } from "../helpers";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
import { ReviewData } from "../types/review";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllReviewsResponse = {
  fakeCount: number;
  // review: never[];
  data: ReviewData;
  count: number;
};

type GetTokenParams = {
  limit?: number;
  page?: number;
  query?: string;
};
// type EditReviewById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };
type EditReviewById = {
  hash: string;
  sek: string;
} | null;
export const ReviewApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query<
      CommonResponseType & { data: GetAllReviewsResponse },
      { id: string | undefined; page: number }
    >({
      query: ({ id, page }) => {
        let url = END_POINTS.review + "/" + id;
        const queryParams = [];
        if (page) {
          queryParams.push(`page=${page}`);
        }
        // if (query) {
        //   queryParams.push(`search=${query}`);
        // }
        // if (limit) {
        //   queryParams.push(`limit=${limit}`);
        // }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    getReviewById: builder.query<
      CommonResponseType & { data: any },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.review}/${id}`,
        method: "GET",
      }),
    }),
    editReviewById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string; body: EditReviewById }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.review}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteReviewById: builder.mutation<
      CommonResponseType & { data: any },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.review}/${id}`,
        method: "DELETE",
      }),
    }),

    addReview: builder.mutation<
      CommonResponseType & { data: any },
      { id: string | undefined; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.review}/${id}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetReviewsQuery,
  useLazyGetReviewByIdQuery,
  useEditReviewByIdMutation,
  useDeleteReviewByIdMutation,
  useAddReviewMutation,
} = ReviewApi;
