import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Button, Menu, MenuItem } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { Box, IconButton } from "@mui/material";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { ADMIN_ROLE_ID } from "../../constants/role";
import useAuth from "../../hooks/useAuth";
import { useLazyGetUnregisteredUsersQuery } from "../../services/user";
import { showError } from "../../constants/toasts";
import AddNotification from "../../Modals/addNotification";
import { exportToExcel } from "../../utils/exportToExcel";
import { exportToPdf } from "../../utils/exportToPdf";
import Loader from "../../constants/Loader";

const UnregisteredUsers = () => {
  // const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const countryCodes: any = {
    1: "United States/Canada",
    7: "Russia/Kazakhstan",
    20: "Egypt",
    27: "South Africa",
    30: "Greece",
    31: "Netherlands",
    32: "Belgium",
    33: "France",
    34: "Spain",
    36: "Hungary",
    39: "Italy",
    40: "Romania",
    41: "Switzerland",
    43: "Austria",
    44: "United Kingdom",
    45: "Denmark",
    46: "Sweden",
    47: "Norway",
    48: "Poland",
    49: "Germany",
    51: "Peru",
    52: "Mexico",
    53: "Cuba",
    54: "Argentina",
    55: "Brazil",
    56: "Chile",
    57: "Colombia",
    58: "Venezuela",
    60: "Malaysia",
    61: "Australia",
    62: "Indonesia",
    63: "Philippines",
    64: "New Zealand",
    65: "Singapore",
    66: "Thailand",
    81: "Japan",
    82: "South Korea",
    84: "Vietnam",
    86: "China",
    90: "Turkey",
    91: "India",
    92: "Pakistan",
    93: "Afghanistan",
    94: "Sri Lanka",
    95: "Myanmar",
    98: "Iran",
    211: "South Sudan",
    212: "Morocco/Western Sahara",
    213: "Algeria",
    216: "Tunisia",
    218: "Libya",
    220: "Gambia",
    221: "Senegal",
    222: "Mauritania",
    223: "Mali",
    224: "Guinea",
    225: "Ivory Coast",
    226: "Burkina Faso",
    227: "Niger",
    228: "Togo",
    229: "Benin",
    230: "Mauritius",
    231: "Liberia",
    232: "Sierra Leone",
    233: "Ghana",
    234: "Nigeria",
    235: "Chad",
    236: "Central African Republic",
    237: "Cameroon",
    238: "Cape Verde",
    239: "Sao Tome and Principe",
    240: "Equatorial Guinea",
    241: "Gabon",
    242: "Republic of the Congo",
    243: "Democratic Republic of the Congo",
    244: "Angola",
    245: "Guinea-Bissau",
    246: "British Indian Ocean Territory",
    247: "Ascension Island",
    248: "Seychelles",
    249: "Sudan",
    250: "Rwanda",
    251: "Ethiopia",
    252: "Somalia",
    253: "Djibouti",
    254: "Kenya",
    255: "Tanzania",
    256: "Uganda",
    257: "Burundi",
    258: "Mozambique",
    260: "Zambia",
    261: "Madagascar",
    262: "Reunion/Mayotte",
    263: "Zimbabwe",
    264: "Namibia",
    265: "Malawi",
    266: "Lesotho",
    267: "Botswana",
    268: "Eswatini",
    269: "Comoros",
    290: "Saint Helena",
    291: "Eritrea",
    297: "Aruba",
    298: "Faroe Islands",
    299: "Greenland",
    350: "Gibraltar",
    351: "Portugal",
    352: "Luxembourg",
    353: "Ireland",
    354: "Iceland",
    355: "Albania",
    356: "Malta",
    357: "Cyprus",
    358: "Finland",
    359: "Bulgaria",
    370: "Lithuania",
    371: "Latvia",
    372: "Estonia",
    373: "Moldova",
    374: "Armenia",
    375: "Belarus",
    376: "Andorra",
    377: "Monaco",
    378: "San Marino",
    379: "Vatican City",
    380: "Ukraine",
    381: "Serbia",
    382: "Montenegro",
    383: "Kosovo",
    385: "Croatia",
    386: "Slovenia",
    387: "Bosnia and Herzegovina",
    389: "North Macedonia",
    420: "Czech Republic",
    421: "Slovakia",
    423: "Liechtenstein",
    500: "Falkland Islands",
    501: "Belize",
    502: "Guatemala",
    503: "El Salvador",
    504: "Honduras",
    505: "Nicaragua",
    506: "Costa Rica",
    507: "Panama",
    508: "Saint Pierre and Miquelon",
    509: "Haiti",
    590: "Guadeloupe",
    591: "Bolivia",
    592: "Guyana",
    593: "Ecuador",
    594: "French Guiana",
    595: "Paraguay",
    596: "Martinique",
    597: "Suriname",
    598: "Uruguay",
    599: "Netherlands Antilles",
    670: "East Timor",
    672: "Australian External Territories",
    673: "Brunei",
    674: "Nauru",
    675: "Papua New Guinea",
    676: "Tonga",
    677: "Solomon Islands",
    678: "Vanuatu",
    679: "Fiji",
    680: "Palau",
    681: "Wallis and Futuna",
    682: "Cook Islands",
    683: "Niue",
    685: "Samoa",
    686: "Kiribati",
    687: "New Caledonia",
    688: "Tuvalu",
    689: "French Polynesia",
    690: "Tokelau",
    691: "Micronesia",
    692: "Marshall Islands",
    850: "North Korea",
    852: "Hong Kong",
    853: "Macau",
    855: "Cambodia",
    856: "Laos",
    870: "Inmarsat",
    880: "Bangladesh",
    886: "Taiwan",
    960: "Maldives",
    961: "Lebanon",
    962: "Jordan",
    963: "Syria",
    964: "Iraq",
    965: "Kuwait",
    966: "Saudi Arabia",
    967: "Yemen",
    968: "Oman",
    970: "Palestinian Territory",
    971: "United Arab Emirates",
    972: "Israel",
    973: "Bahrain",
    974: "Qatar",
    975: "Bhutan",
    976: "Mongolia",
    977: "Nepal",
    992: "Tajikistan",
    993: "Turkmenistan",
    994: "Azerbaijan",
    995: "Georgia",
    996: "Kyrgyzstan",
    998: "Uzbekistan",
  };

  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });

  const gridApi = useGridApiRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [getUsers] = useLazyGetUnregisteredUsersQuery();
  const getUsersList = async () => {
    setIsLoading(true);
    try {
      const response = await getUsers({
        page: 1,
        wpagination: true,
        // limit: 10,
        query: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setUsers(response?.data || []);
      } else {
        setUsers([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getUsersList();
  }, []);
  const user = useAuth();
  function setRowsData() {
    const temp: any = [];

    Array?.isArray(users) &&
      users?.map((data: any) => {
        temp.push({
          id: data?._id,
          name: data?.fullName,
          phone: data?.phone,
          countryCode: data?.countryCode,
          mobile: data?.countryCode + data?.phone,
          email: data?.email,
          image: data?.personVerification?.profileImage,
          membershipId: data?.memberShipId ? "GN" + data?.memberShipId : "-",
          orderSubmited: data?.isOrderSubmitted ? data?.isOrderSubmitted : "-",
          country:
            countryCodes[
              parseInt(data?.countryCode?.slice(1, data?.countryCode?.length))
            ],
          date: data?.createdAt?.slice(0, 10),
          gender: data?.gender
            ? data?.gender == "1"
              ? "Male"
              : "Female"
            : "-",
        });
      });
    if (!rows?.length) setRows(temp);
  }
  useEffect(() => {
    setRowsData();
  }, [users]);

  const [rows, setRows] = useState<any>([]);

  const [searchValue, setSearchValue] = useState("");
  const columns: GridColDef<(typeof rows)[number]>[] = [
    // {
    //   flex: 1,
    //   minWidth: 125,
    //   field: "membershipId",
    //   headerName: "Membership #",
    //   editable: false,
    //   sortable: false,
    //   renderCell: ({ row }) => {
    //     return (
    //       <p className="c_black">
    //         {row?.membershipId ? row?.membershipId : "GN12xxxxxxx"}
    //       </p>
    //     );
    //   },
    // },
    {
      flex: 1,
      minWidth: 200,
      field: "name",
      headerName: "Name",
      editable: false,
      sortable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              style={{ cursor: "default" }}
              // onClick={() =>
              //   user?.role == ADMIN_ROLE_ID ||
              //   user?.subadminRoleId?.permissions?.[6]?.isView
              //     ? navigate("/profile-detail/" + data?.row?.id)
              //     : null
              // }
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <p>
              <b>{data?.row?.name}</b>
              {data?.row?.email}
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      field: "date",
      headerName: "Date",
      editable: false,
    },
    {
      flex: 1,
      field: "gender",
      headerName: "Gender",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 130,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
    },
    {
      flex: 1,
      field: "country",
      headerName: "Country",
      editable: false,
    },
    // {
    //   flex: 1,
    //   minWidth: 130,
    //   field: "orderSubmited",
    //   headerName: "Order submited",
    //   editable: false,
    //   renderCell: ({ row }) => {
    //     return <p className="c_primary">{row?.orderSubmited ? "Yes" : "No"}</p>;
    //   },
    // },
    // {
    //   flex: 1,
    //   minWidth: 140,
    //   field: "socialMedia",
    //   headerName: "Social Media",
    //   editable: false,
    //   sortable: false,
    //   renderCell: () => {
    //     return (
    //       <div className="table_actions">
    //         <Box>
    //           <IconButton className="round_btn">
    //             <img src="/static/images/facebook_icon.svg" alt="Icon" />
    //           </IconButton>
    //         </Box>
    //         <Box>
    //           <IconButton className="round_btn">
    //             <img src="/static/images/instagram_icon.svg" alt="Icon" />
    //           </IconButton>
    //         </Box>
    //         <Box>
    //           <IconButton className="round_btn">
    //             <img src="/static/images/twitter_icon.svg" alt="Icon" />
    //           </IconButton>
    //         </Box>
    //       </div>
    //     );
    //   },
    // },
  ];

  let hidden = ["fullName"];

  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    // orderSubmited: true,
    membershipId: true,
    name: true,
    date: true,
    gender: true,
    mobile: true,
    country: true,
  });
  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Uncompleted Registration Users</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Registered users
        </p>
      </div>
      <div className="cards">
        <div className="table_header">
          <div className="left_s">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setSearchTerm}
              placeholder="Search Users"
              value={searchTerm}
              onCross={() => {
                setSearchTerm("");
                setSearchValue("");
              }}
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
                setSearchValue(e.target.value);
              }}
            />
          </div>
          <div className="right_s">
            {/* <Button className="btn btn_highlight" onClick={() => setOpen(true)}>
              <img src="/static/images/broadcast_icon.svg" alt="Icon" />{" "}
              Broadcast Message
            </Button> */}
            <Box>
              <Button
                className="btn btn_highlight"
                aria-controls={open3 ? "basic-menu3" : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? "true" : undefined}
                onClick={handleClick3}
              >
                <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
              </Button>
              <Menu
                className="filter_menu"
                id="basic-menu3"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}
                MenuListProps={{
                  "aria-labelledby": "basic-button3",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                    borderRadius: "10px",
                    mt: 1,
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <h2>Choose Option</h2>
                  <p>Select an option to export</p>
                </MenuItem>
                <MenuItem className="export_btns">
                  <Button
                    onClick={() => {
                      selectedRow?.row?.length
                        ? exportToPdf(selectedRow?.row, selectedRow?.column)
                        : showError("Please select atleast one row");
                    }}
                  >
                    PDF
                  </Button>
                  <Button
                    onClick={() => {
                      selectedRow?.row?.length
                        ? gridApi.current.exportDataAsCsv()
                        : showError("Please select atleast one row");
                    }}
                  >
                    CSV
                  </Button>
                  <Button
                    onClick={() => {
                      selectedRow?.row?.length
                        ? exportToExcel(selectedRow?.row, selectedRow?.column)
                        : showError("Please select atleast one row");
                    }}
                  >
                    Excel
                  </Button>
                  {/* <Button>Txt</Button> */}
                </MenuItem>
              </Menu>
            </Box>
          </div>
        </div>
        <div className="sorting_table">
          <TableComponent
            columns={columns?.filter((item) => !hidden.includes(item.field))}
            rows={rows?.filter((row: any) =>
              row?.name
                ?.trim()
                .toLowerCase()
                .includes(searchValue?.trim().toLowerCase())
            )}
            setSelectedRow={setSelectedRow}
            gridApi={gridApi}
            columnVisibilityModel={columnVisibilityModel}
          />
        </div>
      </div>
      {/* <AddNotification
        open={open}
        onClose={() => setOpen(false)}
        setOpen={setOpen}
        broadcast={true}
      /> */}
    </>
  );
};

export default UnregisteredUsers;
