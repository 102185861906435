import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import SearchBar from "../../components/SearchBar";
import { Box, Button, Menu, MenuItem, Tabs, Tab } from "@mui/material";
import BronzeList from "../../features/membershipLevel/bronzeList";
import { useLazyGetMembershipLevelQuery } from "../../services/membershipLevel";
import { useGridApiRef } from "@mui/x-data-grid";
import { showError } from "../../constants/toasts";
import { exportToExcel } from "../../utils/exportToExcel";
import { exportToPdf } from "../../utils/exportToPdf";
import { Daum, MembershipPlan } from "../../types/membershipPlan";
import Loader from "../../constants/Loader";

const MembershipLevelBronze = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const gridApi = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });
  const [valueTabs, setValueTabs] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [membershipLevel, setMembershipLevel] = useState<Daum[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
    type3: 0,
  });
  const [type, setType] = useState(1);
  useLayoutEffect(() => {
    if (location?.pathname == "/membership-level/bronze") {
      setType(1);
    } else if (location?.pathname == "/membership-level/silver") {
      setType(2);
    } else {
      setType(3);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (location?.pathname == "/membership-level/bronze") {
      getAllDataList(3);
    } else if (location?.pathname == "/membership-level/silver") {
      getAllDataList(2);
    } else {
      getAllDataList(1);
    }
  }, [location.pathname]);
  const [getAllData] = useLazyGetMembershipLevelQuery();
  const getAllDataList = async (type: number) => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setMembershipLevel(response?.data?.data);
        setTotalDataCount({
          type1: response?.data?.goldenCount,
          type2: response?.data?.silverCount,
          type3: response?.data?.bronzeCount,
        });
      }
    } catch (error: any) {
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const typeName: Record<string | number, string> = {
    1: "bronze",
    2: "silver",
    3: "gold",
    11: "Bronze",
    22: "Silver",
    33: "Gold",
  };
  const getFilteredData = (data: Daum[]) => {
    return data.filter((data) =>
      // data?.userId?.firstName
      //   ?.toLocaleLowerCase()
      //   ?.includes(searchTerm.toString()?.toLocaleLowerCase()) ||
      // data?.userId?.lastName
      //   ?.toLocaleLowerCase()
      //   ?.includes(searchTerm.toString()?.toLocaleLowerCase()) ||
      `${data?.userId?.firstName} ${data?.userId?.lastName}`
        ?.toLocaleLowerCase()
        ?.includes(searchTerm.toString()?.toLocaleLowerCase())
    );
  };
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    traveler: true,
    contact: true,
    loyalPoints: true,
    tripRecorded: true,
    orderNo: true,
    reliability: true,
    commitment: true,
    quality: true,
  });
  return (
    <>
      <div className="main_title">
        <Loader isLoad={isLoading} />
        <h1>Membership Level</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Membership Level - {typeName[`${type}${type}`]}
        </p>
      </div>
      <div className="table_header">
        <div className="left_s">
          <Box className="custom_tabs1" sx={{ width: "100%" }}>
            <Tabs className="custom_tabs_links">
              <Tab
                label={`Bronze Users(${totalDataCount?.type3})`}
                className={type == 1 ? "Mui-selected" : ""}
                onClick={() => navigate("/membership-level/bronze")}
              />
              <Tab
                label={`Silver Users(${totalDataCount?.type2})`}
                className={type == 2 ? "Mui-selected" : ""}
                onClick={() => navigate("/membership-level/silver")}
              />
              <Tab
                label={`Gold Users(${totalDataCount?.type1})`}
                className={type == 3 ? "Mui-selected" : ""}
                onClick={() => navigate("/membership-level/gold")}
              />
            </Tabs>
          </Box>
        </div>
        <div className="right_s">
          {/* <Button className="btn btn_white fw_400" onClick={() => navigate("/broadcast-message")}>Broadcast Message</Button> */}
          <Box className="search_box c_white">
            <SearchBar
              searchTerm={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
              setDebouncedSearchTerm={setSearchTerm}
              placeholder={`Search ${typeName[type]} passenger`}
            />
          </Box>
          <Box>
            <Button
              className="btn btn_secondary"
              aria-controls={open3 ? "basic-menu3" : undefined}
              aria-haspopup="true"
              aria-expanded={open3 ? "true" : undefined}
              onClick={handleClick3}
            >
              <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
            </Button>
            <Menu
              className="filter_menu"
              id="basic-menu3"
              anchorEl={anchorEl3}
              open={open3}
              onClose={handleClose3}
              MenuListProps={{
                "aria-labelledby": "basic-button3",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.13)",
                  borderRadius: "10px",
                  mt: 1,
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <h2>Choose Option</h2>
                <p>Select an option to export</p>
              </MenuItem>
              <MenuItem className="export_btns">
                <Button
                  onClick={() => {
                    selectedRow?.row?.length
                      ? exportToPdf(selectedRow?.row, selectedRow?.column)
                      : showError("Please select atleast one row");
                  }}
                >
                  PDF
                </Button>
                <Button
                  onClick={() => {
                    selectedRow?.row?.length
                      ? gridApi.current.exportDataAsCsv()
                      : showError("Please select atleast one row");
                  }}
                >
                  CSV
                </Button>
                <Button
                  onClick={() => {
                    selectedRow?.row?.length
                      ? exportToExcel(selectedRow?.row, selectedRow?.column)
                      : showError("Please select atleast one row");
                  }}
                >
                  Excel
                </Button>
                {/* <Button>Txt</Button> */}
              </MenuItem>
            </Menu>
          </Box>
        </div>
      </div>

      <div className="cards">
        <BronzeList
          getDataList={getAllDataList}
          setSelectedRow={setSelectedRow}
          gridApi={gridApi}
          membershipLevel={getFilteredData(membershipLevel)}
          columnVisibilityModel={columnVisibilityModel}
        />
      </div>
    </>
  );
};

export default MembershipLevelBronze;
